import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  TextField,
  Paper,
} from "@material-ui/core";
import { FormComponents } from "../../../utils";
import { DateValidations, NumericValidations, StringValidations } from "..";
import { genKey } from "draft-js";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export const EmbedValidations = (props) => {
  const {
    // data: { embedValidation },
    validationState,
    onChange,
  } = props;
  const [fields, setFields] = React.useState([]);

  const classes = useStyles(props);

  const onExtractMentions = () => {
    const raw = JSON.parse(props.editorState);
    let mentionedFields = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedFields.push({ ...ent.data.mention, uuid: genKey() });
      }
    }
    console.log("editorState", mentionedFields);
    return mentionedFields;
  };

  useEffect(() => {
    // if(props.editorState){
    //   setFields(onExtractMentions());
    // }
    if (props.fields) {
      setFields(props.fields);
    }
  }, [props.fields]);

  // Filtering Validation of deleted fields
  const filteredEmbeddedValidations = (validationObject) => {
    const allowedKeys = fields?.map((x) => x.uniqueId); // ["uuid","uuid","uuid","uuid"]
    let finalObject = {};
    allowedKeys.map((key) => {
      finalObject = validationObject[key]
        ? {
            ...finalObject,
            [key]: validationObject[key],
          }
        : { ...finalObject };
      return key;
    });

    return finalObject;
  };

  const handleChange = (value, key, idx) => {
    // debugger;
    let validationObject = {
      ...validationState,
      [idx]: {
        ...validationState?.[`${idx}`],
        [key]: value,
      },
    };
    // debugger;
    let filteredValidationObj = filteredEmbeddedValidations(validationObject);

    onChange(filteredValidationObj, key);
  };

  const getFieldValidations = (field, idx) => {
    switch (field.type) {
      case FormComponents.input_text:
        return (
          <QuestionTagRender
            val={props.val}
            // idx & fieldLength are used for UI purposes
            fieldLength={fields?.length}
            idx={idx}
          >
            <StringValidations
              validationState={validationState?.[`${field?.uniqueId}`]}
              onChange={(value, key) =>
                handleChange(value, key, field?.uniqueId)
              }
              addGridSpacing
            />
          </QuestionTagRender>
        );
      case FormComponents.input_number:
        return (
          <QuestionTagRender
            val={props.val}
            // idx & fieldLength are used for UI purposes
            fieldLength={fields?.length}
            idx={idx}
          >
            <NumericValidations
              validationState={validationState?.[`${field?.uniqueId}`] ?? {}}
              onChange={(value, key) =>
                handleChange(value, key, field?.uniqueId)
              }
              addGridSpacing
            />
          </QuestionTagRender>
        );
      case FormComponents.input_date_picker:
        return (
          <QuestionTagRender
            val={props.val}
            // idx & fieldLength are used for UI purposes
            fieldLength={fields?.length}
            idx={idx}
          >
            <DateValidations
              validationState={validationState?.[`${field?.uniqueId}`] ?? {}}
              onChange={(value, key) =>
                handleChange(value, key, field?.uniqueId)
              }
              addGridSpacing
            />
          </QuestionTagRender>
        );
      case FormComponents.single_tab_select:
        return (
          <QuestionTagRender
            val={props.val}
            // idx & fieldLength are used for UI purposes
            fieldLength={fields?.length}
            idx={idx}
          >
            <React.Fragment>
              <Grid item xs={12}>
                <Typography className={classes.gridItem}>
                  {`Dependency Question`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.data?.has_dependency_qn ?? false}
                      onChange={(e) =>
                        props?.onChangeComponents(
                          !props?.data?.has_dependency_qn ?? false,
                          props?.index,
                          "has_dependency_qn"
                        )
                      }
                      name="hasDependencyQn"
                    />
                  }
                  label="Show another question / form / section based on answer"
                />
              </Grid>
            </React.Fragment>
          </QuestionTagRender>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Configuration- Embed Component</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ background: "rgb(245, 245, 245)" }}>
        {fields.map((field, idx) => {
          return getFieldValidations(field, idx);
        })}
      </Grid>
    </div>
  );
};

export default EmbedValidations;

const useQuestionStyle = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    border: "1px solid rgb(224, 224, 224)",
    padding: "20px 16px",
  },
  topItem: {
    margin: "20px 16px 16px",
  },
  middleItem: {
    margin: "16px",
  },
  bottomItem: {
    margin: "16px 16px 20px",
  },
  inputFiled: {
    // marginBottom: "16px",
  },
  gridItemParent: {},
}));

const QuestionTagRender = ({ val, idx, fieldLength, ...props }) => {
  const classes = useQuestionStyle();
  return (
    <Paper
      className={`${
        idx === 0
          ? classes.topItem
          : idx === fieldLength
          ? classes.bottomItem
          : classes.middleItem
      } ${classes.root}`}
    >
      <Grid container>
        <Grid item style={{ minWidth: 26 }}>
          <Typography>{`${idx + 1}.`}</Typography>
        </Grid>
        <Grid container item xs spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.inputFiled}
          >
            <TextField
              value={"embed"}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              //   label="Question / Title"
              id="question"
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.inputFiled}
          >
            <TextField
              value={val?.tag ?? ""}
              //   onChange={(e) =>
              //     props.onChangeComponents(e.target.value, index, "tag")
              //   }
              onChange={() => {}}
              fullWidth
              label="#Tag"
              id="tag"
              variant="outlined"
              size="small"
              error={val?.error?.tagError ?? false}
              helperText={
                val?.error?.tagError
                  ? "Please give the tag (must be more then two character)"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItemParent}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
