
export const queries = {
    //1. To get all forms
    getAllForms: (formName, sortField, sortDirection, formTypeId, formId, diseases, countryIds, formIds, created_by) => {
        return `
            ${diseases?.length > 0 || countryIds?.length > 0 ? `
                let rlFormIds = (
                    for rld in rl_disease_form
                        filter rld.is_active == true
                        ${diseases?.length > 0 ? `filter rld.disease_id in ${JSON.stringify(diseases)}` : ``}
                        ${countryIds?.length > 0 ? `filter rld.country_id in ${JSON.stringify(countryIds)}` : ``}
                        return rld.form_id
                )
            ` : ``}
    
            for doc in forms 
                filter doc.is_active == true 
                ${created_by ? `|| doc.created_by == "${created_by}"` : ``}
                ${formName ? `FILTER UPPER(doc.form_name) like "%${formName.toUpperCase()}%"` : ``}
                ${formTypeId ? `&& doc.form_type_id == "${formTypeId}"` : ``}
                ${formId ? `FILTER doc.form_id == "${formId}"` : ``}
                ${diseases?.length > 0 || countryIds?.length > 0 ? `filter doc.form_id in rlFormIds` : ``}
                ${formIds?.length > 0 ? `|| doc.form_id in ${JSON.stringify(formIds)}` : ``}
                SORT doc.${sortField} ${sortDirection}  
                LET types = (
                    for ms in master_form_types 
                    filter ms.form_type_id == doc.form_type_id 
                        return {
                            form_type_name : ms.form_type_name ,
                            form_type_id: ms.form_type_id
                        }
                )
                return { 
                    form_id : doc.form_id , 
                    form_name: doc.form_name ,
                    value: doc.form_name ,
                    label: doc.form_name ,
                    form_type:types[0] , 
                    created_at : doc.created_at , 
                    updated_at: doc.updated_at,
                    notification_count: LENGTH(
                        for entry in form_entries
                            filter entry.form_id == doc.form_id
                            return entry
                    ) 
                }
        `
    },

}