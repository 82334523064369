import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import {
  DependencyTable,
  StringValidations,
  NumericValidations,
  DateValidations,
  TableValidations,
  DobValidations,
  DefaultSection,
  MultitagOwn,
  EmbedValidations,
} from '../../components';
import { ComponentType } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  addDependency: {
    marginTop: 12,
  },
  root1:{
    flex: 1
},
gridItem1:{
    padding:theme.spacing(2),
    '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button':{ 
        margin: 0,
        display:'none'
      }
},
}));

export default function QuestionProperties(props) {
  const classes = useStyles(props);
  const [validationState, setValidationState] = useState(
    props?.data?.validation ?? {}
  );

  const stringComponentType = (type) => {
    const optionComponentsList = ["input_text", "input_textarea"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const dateComponentType = (type) => {
    const optionComponentsList = ["input_date_picker"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const dateOfBirthComponentType = (type) => {
    const optionComponentsList = ["date_of_birth"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const numericComponentType = (type) => {
    const optionComponentsList = ["input_number"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const tableComponentType = (type) => {
    const optionComponentsList = ["custom_table", "custom_table_2","ordinary_table","table_type_3"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const defaultSection = (type) => {
    const optionComponentsList = [
      "radio_button",
      "check_box",
      "select_modal_dropdown",
      "multi_select_tag",
    ];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const embedComponentType = (type) => {
    const optionComponentsList = ["embed_input"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const ownMultiTag = (type) => {
    const optionComponentsList = ['custom_multi_select_tag'];
    if (optionComponentsList.includes(type)) {
      return true
    } else {
      return false
    }
  }

  const noValidation = (type) => {
    const optionComponentsList = ['bmi_input','bp_input','formula_builder','temperature_input','mobile_no',"note","label","location"];
    if (optionComponentsList.includes(type)) {
      return true
    } else {
      return false
    }
  }

  const onChange = (val, key) => {
    validationState[key] = val ? val : "";
    props?.onChangeComponents(validationState, props?.index, "validation");

    setValidationState(validationState);
  };

  const onEmbedChange = (val) => {
    // debugger;
    // props?.onChangeComponents(val, props?.index, "embedValidation");
    props?.onChangeComponents(val, props?.index, "validation");

    setValidationState(val);
  };

  React.useEffect(() => {
    if (props?.data?.validation) {
      setValidationState(props?.data?.validation)
    }
    // eslint-disable-next-line
  }, [props?.data?.validation])


  return (
    <div className={classes.root}>
      <Grid container>
        {
          defaultSection(props?.data?.component ?? '') &&
          <DefaultSection
            data={props?.data}
            validationState={validationState}
            onChange={onChange}
          />
        }
        {
          ownMultiTag(props?.data?.component ?? '') &&
          <MultitagOwn
            data={props?.data}
            validationState={validationState}
            onChange={onChange}
          />
        }
        {
          ComponentType(props?.data?.component ?? "") &&
          <React.Fragment>
            <Grid item xs={12}>
              <Typography className={classes.gridItem}>
                {`Dependency Question`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.data?.has_dependency_qn ?? false}
                    onChange={(e) => props?.onChangeComponents(!props?.data?.has_dependency_qn ?? false, props?.index, "has_dependency_qn")}
                    name="hasDependencyQn"
                  />
                }
                label="Show another question / form / section based on answer"
              />
            </Grid>
          </React.Fragment>
        }
        {props?.data?.has_dependency_qn && <Grid item xs={12} className={classes.gridItem}>
          <DependencyTable
            data={props?.dependency}
            options={props?.options}
            parentSection={props?.parentSection}
            selectedSection={props?.selectedSection}
            quetions={props?.fromData}
            removeDependency={props?.addDeleteComponents}
            updateDependency={props?.updateDependency}
            parentIndex={props?.index}
          />

          <Button
            className={classes.addDependency}
            variant={"outlined"}
            size={"small"}
            color={"primary"}
            onClick={() => props?.addDeleteComponents(props?.data, props?.index, true)}
          >
            + Add
          </Button>
        </Grid>
        }
        {
          stringComponentType(props?.data?.component ?? '') &&
          <StringValidations
            validationState={validationState}
            onChange={onChange}
          />
        }
        {
          numericComponentType(props?.data?.component ?? '') &&
          <NumericValidations
            validationState={validationState}
            onChange={onChange}
          />
        }
        {
          dateComponentType(props?.data?.component ?? '') &&
          <DateValidations
            validationState={validationState}
            onChange={onChange}
          />
        }
        {
          tableComponentType(props?.data?.component ?? '') &&
          <TableValidations
            onChangeComponents={(data, state) => props?.onChangeComponents(data, props?.index, state)}
            data={props?.data}
          />
        }

        {dateOfBirthComponentType(props?.data?.component ?? "") && (
          <DobValidations
            validationState={validationState}
            onChange={onChange}
          />
        )}

        {embedComponentType(props?.data?.component ?? "") && (
          <EmbedValidations
            validationState={validationState}
            onChange={onEmbedChange}
            // editorState={props.editorState}
            editorState={props.data.editorState}
            fields={props.data.embedFields}
            // editorState={
            //   '{"blocks":[{"key":"a98sm","text":"Textfield is what i need","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":9,"key":0}],"data":{}}],"entityMap":{"0":{"type":"mention","mutability":"SEGMENTED","data":{"mention":{"name":"Textfield","type":"input_text"}}}}}'
            // }
            {...props}
          />
        )}

        {noValidation(props?.data?.component ?? "") && (
          <Grid container>
          <Grid item xs={12} md={12} className={classes.gridItem1}>
          <center style={{marginTop:"30px"}}><h2>No Validation available for this component</h2></center>
          </Grid>
          </Grid>
        )}


      </Grid>
    </div>
  );
}
