import React from "react";

export const Expand = (props) => {
  let width = props.width ? props.width : "24";
  let height = props.height ? props.height : "24";
  let color = props.color ? props.color : "#000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
       {...props}
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M21.414 18.586l2.586-2.586v8h-8l2.586-2.586-5.172-5.172 2.828-2.828 5.172 5.172zm-13.656-8l2.828-2.828-5.172-5.172 2.586-2.586h-8v8l2.586-2.586 5.172 5.172zm10.828-8l-2.586-2.586h8v8l-2.586-2.586-5.172 5.172-2.828-2.828 5.172-5.172zm-8 13.656l-2.828-2.828-5.172 5.172-2.586-2.586v8h8l-2.586-2.586 5.172-5.172z"
            fill={color}
        />
        </svg>
  );
};