import React, { useState } from "react";
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Checkbox,
  TextField,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CallIcon from '@material-ui/icons/Call';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { DrawerComponent } from '../../../componentsV2';
import { Mobile, Notify, RadioButton, CheckBox } from '../../../components';
// import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      backgroundColor: '#E5EAF4',
      padding: '5px 16px',
      minWidth: 150,
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  addColumn: {
    '& .MuiIconButton-root': {
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }
  },
  addRowBtn: {
    minWidth: 150
  }
}));
const CustomTable = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectIndex, setSelectIndex] = React.useState('');

  const [dropdownData, setDropdownData] = React.useState([]);

  const [radioData, setRadioData] = React.useState([]);
  const [checkBoxData, setCheckBoxData] = React.useState([]);
  const [addColData, setAddColData] = React.useState('');
  const [labelData, setLabelData] = React.useState('');

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (val) => {
    setDrawerOpen(val)
    if (!val) {
      handleClose();
      setAddColData('');
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, index) => {
    setSelectIndex(index)
    handleClick(event)
  }

  const handleClose = () => {
    setSelectIndex('')
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [toggleInput, setToggleInput] = useState({
    index: '',
    toggle: false
  });

  const [inputValue, setInputValue] = useState('');

  const uiComponentPopover = [
    { component_name: 'Checkbox', ui_component: 'check_box', icon: 'CheckBoxOutlinedIcon' },
    { component_name: 'Input Text', ui_component: 'input_text', icon: 'TextFieldsOutlinedIcon' },
    { component_name: 'Date', ui_component: 'date', icon: 'CalendarTodayOutlinedIcon' },
    { component_name: 'Dropdown', ui_component: 'dropdown', icon: 'ArrowDropDownIcon' },
    { component_name: 'Mobile', ui_component: 'mobile', icon: 'CallIcon' },
    { component_name: 'Notify', ui_component: 'Notify', icon: 'LabelOutlinedIcon' },
    { component_name: 'Radio', ui_component: 'Radio', icon: 'RadioButtonCheckedOutlinedIcon' },
    { component_name: 'Custom Check Box', ui_component: 'custom_Check_box', icon: 'CheckBoxOutlinedIcon' },
  ]

  const giveMeIcon = (icon) => {
    switch (icon) {
      case 'CheckBoxOutlinedIcon':
        return <CheckBoxOutlinedIcon />
      case 'TextFieldsOutlinedIcon':
        return <TextFieldsOutlinedIcon />
      case 'CalendarTodayOutlinedIcon':
        return <CalendarTodayOutlinedIcon />
      case 'ArrowDropDownIcon':
        return <ArrowDropDownIcon />
      case 'CallIcon':
        return <CallIcon />
      case 'RadioButtonCheckedOutlinedIcon':
        return <RadioButtonCheckedOutlinedIcon />
      case 'CheckBoxOutlinedIcon':
        return <CheckBoxOutlinedIcon />
      case 'LabelOutlinedIcon':
        return <LabelOutlinedIcon />
      default:
        return <CheckBoxOutlinedIcon />
    }
  }

  const addDropdownValues = (value) => {
    setDropdownData(value)
  }
  const addRadioValues = (value) => {
    setRadioData(value)
  }
  const addCheckValues = (value) => {
    setCheckBoxData(value)
  }
  const addLabelValues = (value) => {
    setLabelData(value)
  }

  const addColumn = (col) => {
    setAddColData(col?.component_name);
    //delete the validation key because of changing column type
    if (selectIndex && selectIndex >= 0) {
      props?.data?.tableScheme?.row?.filter(l => {
        if (l?.validations?.hasOwnProperty(selectIndex)) {
          let deleteObj = { ...l?.validations }
          delete deleteObj[selectIndex]
          l.validations = deleteObj
        }
        return l
      })
    }

    if (col?.component_name === 'Dropdown') {
      toggleDrawer(true)
      setDropdownData(props?.data?.tableScheme.column[selectIndex]?.options ?? [])
    } else if (col?.component_name === 'Radio') {
      toggleDrawer(true)
      setRadioData(props?.data?.tableScheme.column[selectIndex]?.options ?? [])

    } else if (col?.component_name === 'Custom Check Box') {
      toggleDrawer(true)
      setCheckBoxData(props?.data?.tableScheme.column[selectIndex]?.options ?? [])
    } else if (col?.component_name === 'Notify') {
      toggleDrawer(true)
      if (typeof (props?.data?.tableScheme.column[selectIndex]) === 'object') {
        setLabelData('')
      } else {
        setLabelData(props?.data?.tableScheme.column[selectIndex] ?? '')
      }
    }
    else {
      if (selectIndex !== '' && selectIndex >= 0) {
        if (col?.component_name !== 'Dropdown') {
          let deleteOption = props?.data?.tableScheme.column[selectIndex];
          delete deleteOption['options'];
          Object.assign(deleteOption, col)
        }
        else {
          Object.assign(props?.data?.tableScheme.column[selectIndex], col)
        }
      }
      else {
        props?.data?.tableScheme?.column.push({
          name: 'Column Type',
          ...col
        })
      }
      handleClose();
      props?.onChangeComponents(props?.data?.tableScheme);
    }
  }

  const addDropdownOptions = {
    component_name: 'Dropdown',
    ui_component: 'dropdown',
    icon: 'ArrowDropDownIcon',
    options: dropdownData
  }


  const addRadioOptions = {
    component_name: 'Radio',
    ui_component: 'Radio',
    icon: 'RadioButtonCheckedOutlinedIcon',
    options: radioData
  }

  const addLabelOptions = {
    component_name: 'Notify',
    ui_component: 'Notify',
    icon: 'LabelOutlinedIcon',
    value: labelData
  }

  const addCheckOptions = {
    component_name: 'Custom Check Box',
    ui_component: 'custom_Check_box',
    icon: 'CheckBoxOutlinedIcon',
    options: checkBoxData
  }


  const saveFunc = () => {
    if (selectIndex !== '' && selectIndex >= 0) {
      Object.assign(props?.data?.tableScheme.column[selectIndex], addDropdownOptions)
    }
    else {
      props?.data?.tableScheme?.column.push({
        name: 'Column Type',
        ...addDropdownOptions
      })
    }
    props?.onChangeComponents(props?.data?.tableScheme);
    handleClose();
    toggleDrawer(false);
    setAddColData('');
  }

  const saveRadioOptFunc = () => {
    if (selectIndex !== '' && selectIndex >= 0) {
      Object.assign(props?.data?.tableScheme.column[selectIndex], addRadioOptions)
    }
    else {
      props?.data?.tableScheme?.column.push({
        name: 'Column Type',
        ...addRadioOptions
      })
    }
    props?.onChangeComponents(props?.data?.tableScheme);
    handleClose();
    toggleDrawer(false);
    setAddColData('');
  }

  const saveLabelFunc = () => {
    if (selectIndex !== '' && selectIndex >= 0) {
      Object.assign(props?.data?.tableScheme.column[selectIndex], addLabelOptions)
    }
    else {
      props?.data?.tableScheme?.column.push({
        name: 'Column Type',
        ...addLabelOptions
      })
    }
    props?.onChangeComponents(props?.data?.tableScheme);
    handleClose();
    toggleDrawer(false);
    setAddColData('');
  }

  const saveCheckOptFunc = () => {
    if (selectIndex !== '' && selectIndex >= 0) {
      Object.assign(props?.data?.tableScheme.column[selectIndex], checkBoxData)
    }
    else {
      props?.data?.tableScheme?.column.push({
        name: 'Column Type',
        ...addCheckOptions
      })
    }
    props?.onChangeComponents(props?.data?.tableScheme);
    handleClose();
    toggleDrawer(false);
    setAddColData('');
  }

  const deleteColumn = () => {
    const filteredData = props?.data?.tableScheme?.column.filter((l, i) => i !== selectIndex)
    props.data.tableScheme.column = filteredData;
    handleClose()
    props?.onChangeComponents(props?.data?.tableScheme);
  }

  const addRow = () => {
    props?.data?.tableScheme?.row.push({
      name: 'Row Type'
    })
    props?.onChangeComponents(props?.data?.tableScheme);
  }

  const deleteRow = (index) => {
    const filteredData = props?.data?.tableScheme?.row?.filter((l, i) => i !== index)
    props.data.tableScheme.row = filteredData;
    props?.onChangeComponents(props?.data?.tableScheme);
  }

  const clickColumnName = (columnData, index) => {
    setToggleInput({
      toggle: true,
      index
    })
    setInputValue(columnData.name)
  }

  const onChangeColumnName = (value, index) => {
    setInputValue(value);
    props.data.tableScheme.column[index].name = value
    props?.onChangeComponents(props?.data?.tableScheme);
  }

  const blur = () => {
    setToggleInput({
      ...toggleInput, toggle: false,
    });
    setInputValue('');
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell variant="head" style={{ width: 20, minWidth: 20 }}>
                <Typography variant="body1">
                  Actions
                </Typography>
              </TableCell>
              {
                props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData, index) => {
                  return (
                    <TableCell key={index} variant="head">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" onClick={() => clickColumnName(colData, index)}>
                          {toggleInput.toggle && toggleInput.index === index ? (
                            <TextField
                              autoFocus
                              value={inputValue}
                              onChange={(e) => onChangeColumnName(e.target.value, index)}
                              onBlur={blur}
                            />
                          ) : colData.name}
                        </Typography>
                        <IconButton size="small" aria-describedby={id} onClick={(event) => openPopover(event, index)}>
                          <ExpandMoreOutlinedIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  )
                })
              }
              <TableCell>
                <Button variant="text" color="secondary" className={classes.addColumn}>
                  <IconButton aria-describedby={id} onClick={handleClick} disableTouchRipple disableFocusRipple disableRipple>
                    <Typography color="secondary">Add Column &nbsp;</Typography>
                    <AddCircleOutlineOutlinedIcon color="secondary" />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <List>
                      {
                        uiComponentPopover && uiComponentPopover.map(l => {
                          return (
                            <ListItem button onClick={() => addColumn(l)}>
                              <ListItemIcon>
                                {giveMeIcon(l.icon)}
                              </ListItemIcon>
                              <ListItemText primary={l.component_name} />
                            </ListItem>
                          )
                        })
                      }
                      {
                        selectIndex && parseInt(selectIndex) > 0 ? (
                          <ListItem button onClick={() => deleteColumn()}>
                            <ListItemIcon>
                              <DeleteOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Delete Column'} />
                          </ListItem>) : null
                      }
                    </List>
                  </Popover>
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props?.data?.tableScheme?.row && props?.data?.tableScheme?.row?.map((rowData, i1) => {
                return (
                  <TableRow key={i1}>
                    <TableCell>
                      <IconButton onClick={() => deleteRow(i1)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                    {
                      props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData, i2) => {
                        switch (colData.ui_component) {
                          case 'check_box':
                            return (
                              <TableCell key={i2}>
                                <Checkbox
                                  // checked={false}
                                  // onChange={handleChange}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                            )
                          case 'input_text':
                            return (
                              <TableCell key={i2}>
                                <TextField
                                // value={rowData[l][d].value}
                                // onChange={(value) => onChange(index, data, value)}
                                // label={rowData[l][d].label}
                                />
                              </TableCell>
                            )
                          case 'date':
                            return (
                              <TableCell key={i2}>
                                <form noValidate>
                                  <TextField
                                    id="date"
                                    type="date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )
                          case 'dropdown':
                            return (
                              <TableCell key={i2}>
                                <FormControl>
                                  <Select
                                  // id="demo-dropdown-select"
                                  // value={colData?.component_name ?? 'Input Text'}
                                  // onChange={(e)=>changeDropDownColumn(e,colData,index)}
                                  >
                                    {
                                      colData?.options && colData?.options.map(l => {
                                        return (
                                          <MenuItem value={l}>{l}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </TableCell>
                            )
                          case 'Radio':
                            return (
                              <TableCell key={i2}>
                                <>
                                  <RadioButton
                                    // heading={data?.question}
                                    option={colData?.options.map(_ => {
                                      return {
                                        value: _,
                                        label: _
                                      }
                                    })}
                                    direction="row"
                                    // value={value}
                                    // handleChange={(value) => onChange(index, data, value)}
                                    isReadonly={true}
                                  // isrequired={isrequired}
                                  // errorValidation={errorValidation ?? {}}
                                  // disabled={disabled ?? false}

                                  />
                                </>
                              </TableCell>
                            )
                          case 'custom_Check_box':
                            return (
                              <TableCell key={i2}>
                                <>
                                  <CheckBox
                                    // heading={data?.question}
                                    option={colData?.options?.map(_ => {
                                      return {
                                        value: _,
                                        label: _
                                      }
                                    })}
                                    direction="row"
                                    // checked={value?.length > 0 ? value : []}
                                    // handleChange={(value) => onChange(index, data, value)}
                                    isReadonly={true}
                                  // isrequired={isrequired}
                                  // errorValidation={errorValidation ?? {}}
                                  // disabled={disabled ?? false}

                                  />
                                </>
                              </TableCell>
                            )
                          case 'mobile':
                            return (
                              <TableCell key={i2} style={{ minWidth: 300 }}>
                                <Mobile
                                // value={value}
                                // label={data?.question}
                                // type={"number"}
                                />
                              </TableCell>
                            )
                          case 'Notify':
                            return (
                              <TableCell key={i2} style={{ minWidth: 300 }}>
                                <Notify
                                  text={colData?.value}
                                />
                              </TableCell>
                            )
                          default:
                            return (
                              <TableCell key={i2}>
                                <TextField
                                // value={rowData[l][d].value}
                                // onChange={(value) => onChange(index, data, value)}
                                // label={rowData[l][d].label}
                                />
                              </TableCell>
                            )
                        }
                      })
                    }
                  </TableRow>
                )
              })
            }
            <Button variant="text" color="secondary" className={classes.addRowBtn} onClick={addRow}>
              <Typography>Add Row</Typography>
              <IconButton>
                <AddCircleOutlineOutlinedIcon color="secondary" />
              </IconButton>
            </Button>
          </TableBody>
        </Table>
      </TableContainer>
      {
        drawerOpen &&
        <DrawerComponent open={drawerOpen} onClose={() => toggleDrawer(false)}>
          <Grid container style={{ padding: 16 }}>
            <Grid item xs={12}>
              {(addColData === 'Dropdown') && <Autocomplete
                multiple
                id="dropdown-tags-outlined"
                size={"small"}
                options={[]}
                value={dropdownData}
                freeSolo
                onChange={(e, newValue) => addDropdownValues(newValue)}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Options" placeholder=" Options"
                  // error={val?.error?.optionError ?? false}
                  // helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                  />
                )}
              />}

              {(addColData === 'Radio') && <Autocomplete
                multiple
                id="dropdown-tags-outlined"
                size={"small"}
                options={[]}
                value={radioData}
                freeSolo
                onChange={(e, newValue) => addRadioValues(newValue)}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Options" placeholder=" Radio Options"
                  // error={val?.error?.optionError ?? false}
                  // helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                  />
                )}
              />}


              {(addColData === 'Notify') &&
                // <Autocomplete
                // multiple
                // id="dropdown-tags-outlined"
                // size={"small"}
                // options={[]}
                // value={labelData}
                // freeSolo
                // onChange={(e, newValue) => addLabelValues(newValue)}
                // renderTags={(value, getTagProps) =>
                //   value?.map((option, index) => (
                //     <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                //   ))
                // }
                // renderInput={(params) => (
                <TextField
                  // type={props.type}
                  // id={props.id}
                  label={"Type here !"}
                  // required={props.isrequired}
                  variant={"outlined"}
                  fullWidth
                  // InputLabelProps={{
                  //   shrink: false
                  // }}
                  // inputProps={{ readOnly: props?.isReadonly ?? false }}
                  // disabled={props?.disabled ?? false}
                  size="small"
                  // multiline={props.multiline}
                  // rows={5}
                  // rowsMax={10}
                  // onChange={(e) => props?.onChange(e.target.value)}
                  onChange={(e) => addLabelValues(e.target.value)}
                  value={labelData}
                // onBlur={validations}
                // error={val?.error?.optionError ?? false}
                // helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                />
                // )}
                // />
              }


              {(addColData === 'Custom Check Box') && <Autocomplete
                multiple
                id="dropdown-tags-outlined"
                size={"small"}
                options={[]}
                value={checkBoxData}
                freeSolo
                onChange={(e, newValue) => addCheckValues(newValue)}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Options" placeholder=" CheckBox Options"
                  // error={val?.error?.optionError ?? false}
                  // helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                  />
                )}
              />}

              {(addColData === 'Dropdown') && <Button color="primary" variant="contained" style={{ marginTop: 16 }} onClick={saveFunc}>Save</Button>}

              {(addColData === 'Radio') && <Button color="primary" variant="contained" style={{ marginTop: 16 }} onClick={saveRadioOptFunc}>Save Radio</Button>}
              {(addColData === 'Notify') && <Button color="primary" variant="contained" style={{ marginTop: 16 }} onClick={saveLabelFunc}>Save label</Button>}
              {(addColData === 'Custom Check Box') && <Button color="primary" variant="contained" style={{ marginTop: 16 }} onClick={saveCheckOptFunc}>Save Check Box</Button>}
            </Grid>
          </Grid>
        </DrawerComponent>
      }
    </div>
  );
};
//  CustomTable.propTypes = {

//  };
export default CustomTable;

