import React, { useState, useRef, useCallback } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";

import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";

import editorStyles from "./editorStyles.module.css";
import mentions from "./fieldMentions";
// import "draft-js-mention-plugin/lib/plugin.css";
import { Typography } from "@material-ui/core";
import { Input } from "..";
import createMentionEntities from "./utils";
import "@draft-js-plugins/mention/lib/plugin.css";

// Draft-JS-Mentions plugin configuration
// const mentionPlugin = createMentionPlugin();
// const { MentionSuggestions } = mentionPlugin;
// const plugins = [mentionPlugin];
// const mentionDecorator = mentionPlugin.decorators;
// const compositeDec = new CompositeDecorator(mentionDecorator);

const EmbedInput = (props) => {
  const [suggestions, setSuggestions] = useState(mentions);
  const [renderData, setRenderData] = useState([]);
  const [open, setOpen] = useState(false);


  const { MentionSuggestions, MentionPlugin, MentionDecorators } =
    React.useMemo(() => {
      const MentionPlugin = createMentionPlugin();
      const MentionSuggestions = MentionPlugin.MentionSuggestions;
      const MentionDecorators = MentionPlugin.decorators;
      return { MentionPlugin, MentionSuggestions, MentionDecorators };
    }, []);

  React.useEffect(async () => {
    await setEditorState(restoreFromDb());
  }, [props?.value]);

  const restoreFromDb = () => {
    const decorators = [MentionDecorators];
    if (decorators?.length) {
      const decoratorsArr = decorators
        ?.map((decorator) => decorator)
        .flat(Infinity);
      const decorator = new CompositeDecorator(decoratorsArr);

      const value = props?.value?.editorState ? JSON.parse(props?.value?.editorState) : "";
      if (value) {
        if (value?.blocks && value?.entityMap) {
          // return an Editor state based on the JS Object
          return EditorState.createWithContent(
            convertFromRaw(value),
            decorator
          );
        } else {
          return EditorState.createEmpty(decorator);
        }
      } else {
        return EditorState.createEmpty(decorator);
      }
    }
  };

  // Draft-JS editor configuration
  const [editorState, setEditorState] = useState(() =>
    restoreFromDb()
  );
  const editor = useRef(null);


  // Focus on editor window
  const focusEditor = () => {
    editor.current.focus();
  };

  // My Functions
  const onChange = (_editorState) => {
    // debugger;
    setEditorState(_editorState);

    // Update Parent State on onChange
    props?.onChange(_editorState)



    // props.onChange(onExtractData());
    // handleUiPreview();
  };

  // const updateMention = () => {
  //   let contentState = editorState.getCurrentContent();
  //   let entityKey = contentState.getLastCreatedEntityKey();
  //   contentState.mergeEntityData(entityKey, {"uuid": Math.floor(Math.random() * 100) + 1});
  // }

  // Check editor text for mentions

  const onSearchChange = ({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  };

  const onExtractData = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    console.log(raw);
    return raw;
  };

  const onExtractMentions = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    let mentionedFields = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedFields.push(ent.data.mention);
      }
    }
    // console.log(mentionedFields);
    return mentionedFields;
  };

  const handleUiPreview = () => {
    const { blocks } = onExtractData();

    const entityMapList = onExtractMentions();

    const { text, entityRanges } = blocks[0];

    // Map entityRanges which has the offset and length of the mentions in editor
    // return an object with start and end properties.
    let mentionRanges = entityRanges.map((x) => {
      return {
        start: x.offset,
        end: x.offset + x.length,
      };
    });

    // Create a final array which is used to push the string and input fields in order.
    let finalArray = [];
    let previousOffset = 0;

    if (mentionRanges.length > 0) {
      // Loop mention ranges to replace mentions with fields
      mentionRanges.map((sp, index) => {
        // Getting the string in between field mentions
        let plainText = text.slice(previousOffset, sp.start);
        finalArray.push(plainText);

        let componentToRender = text.slice(sp.start, sp.end);
        // debugger;
        switch (componentToRender.toLowerCase()) {
          case "textfield": {
            finalArray.push(
              <div style={{ margin: "0px 8px" }}>
                <Input {...entityMapList?.[index]?.props} isReadonly={props?.isReadyOnly} />
              </div>
            );
            break;
          }
          case "numberfield": {
            finalArray.push(
              <div style={{ margin: "0px 8px" }}>
                <Input {...entityMapList?.[index]?.props} isReadonly={props?.isReadyOnly} />
              </div>
            );
            break;
          }
          case "shortparagraph": {
            finalArray.push(
              <div style={{ margin: "0px 8px" }}>
                <Input {...entityMapList?.[index]?.props} isReadonly={props?.isReadyOnly} />
              </div>
            );
            break;
          }
          default:
            break;
        }

        //Need to change previousOffset to current end
        previousOffset = sp.end;

        if (index === mentionRanges.length - 1 && sp.end !== text.length) {
          finalArray.push(text.slice(sp.end));
        }

        return sp;
      });
    } else {
      finalArray.push(text);
    }

    setRenderData(finalArray);
  };

  // Error handling
  const isEditorEmpty = (state) => {
    return (
      !state.getCurrentContent().hasText() ||
      state.getCurrentContent().getPlainText().trim().length === 0
    );
  };
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  // plugins
  const plugins = [MentionPlugin];
  return (
    <div tabIndex="0" className={editorStyles.readOnly}>
      {getLabel(props)}
      {isEditorEmpty && (
        <div className={editorStyles.editor}>
          <Editor
            editorState={editorState}
            onChange={(_editorState) => onChange(_editorState)}
            // onChange={setEditorState}
            plugins={plugins}
            readOnly={props.isReadonly}
            // handleReturn={() => 'handled'}
            decorators={MentionDecorators}
          />

          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            onSearchChange={onSearchChange}
            suggestions={suggestions}
            onAddMention={(men) => {
              // if(!men?.uuid){
              //   men["uuid"]= Math.floor(Math.random() * 100) + 1
              // }
              // debugger
              // console.log(men)
            }}
          />
        </div>
      )}
      {/* <div>
        <button onClick={() => onExtractData()}>Preview</button>
        <button onClick={() => onExtractMentions()}>Extract mentions</button>
        <button onClick={() => handleUiPreview()}>UI Preview</button>
        <button
          onClick={() => {
            console.log(editorState);
          }}
        >
          Get Decorator
        </button>
      </div> */}


      {/* UI Preview */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {renderData.map((x) => {
          switch (typeof x) {
            case "string":
              return <Typography>{x}</Typography>;
            case "object":
              return x;
            default:
              return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default EmbedInput;


const getLabel = (props) => {
  return (
    <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
      {props.label}{" "}
      {props.isrequired && (
        <Typography variant="caption" style={{ color: "red" }}>
          *
        </Typography>
      )}
    </Typography>
  );
};
