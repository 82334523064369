/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-06-29
 * @desc Collection of all helper functions.
 */

import moment from "moment";
import { Routes } from "../router/routes";
import { subNavBarArr } from "./constants";

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
  let elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

/**
 * The below function will help in decide the component type
 */

export const ComponentType = (type) => {
  const optionComponentsList = [
    "smart skip",
    "popup_switch",
    "list_text",
    "custom_multi_select_tag",
    "input_text",
    "input_textarea",
    "input_date_picker",
    "input_number",
    "hours_minutes_range",
    "label",
    "note",
    "location",
    "custom_table",
    "custom_table_2",
    "mobile_no",
    "location_level",
    "date_of_birth",
    "file_upload",
    "temperature_input",
    "Image_only_upload",
    "bp_input",
    "bmi_input",
    "embed_input",
    "formula_builder",
    "ordinary_table",
    "table_type_3",
    "terms_and_condition",
  ];
  if (optionComponentsList.includes(type)) {
    return false;
  } else {
    return true;
  }
};

export const EmbedType = (type) => {
  const embedcomponent = ["embed_input"];
  if (embedcomponent.includes(type)) {
    return true;
  } else {
    return false;
  }
};


export const giveMeSubNavBars = (role) => {
  let navBarIds = [];
  let mapped_roles = role?.mapped_roles;
  for (const key in mapped_roles) {
    if (Object?.hasOwnProperty?.call(mapped_roles, key)) {
      const element = mapped_roles?.[key];
      let rolePrivilages = element?.previlages ?? {};
      let previlageKeys = Object.keys(rolePrivilages).filter(
        (pk) => element?.previlages?.[pk].length > 0
      );
      navBarIds.push(...previlageKeys);
    }
    break;
  }
  return subNavBarArr
    .filter((sb) => navBarIds.indexOf(sb.id) > -1)
    .sort((a, b) =>
      a.prioriy > b.prioriy ? 1 : b.prioriy > a.prioriy ? -1 : 0
    );
};

export const amIableToDoThis = (module, op, data) => {
  let isIamOk = false;

  let mapped_roles = data?.mapped_roles;
  for (const key in mapped_roles) {
    if (Object.hasOwnProperty.call(mapped_roles, key)) {
      const element = mapped_roles[key];
      isIamOk = element?.previlages?.[module]?.indexOf(op) > -1;
      break;
    }
  }

  return isIamOk;
};

export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

export const giveMeRoleDataSecurity = (data) => {
  let roleDataSecurity = {
    country: [],
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
    level6: [],
    level7: [],
    level8: [],
    level9: [],
    level10: [],
    level11: [],
    level12: [],
    disease: [],
    form: [],
    myRole: [],
    isOnlyNotifier: false,
  };

  let mapped_roles = [data?.mapped_roles?.[0] ?? {}];

  for (const key in mapped_roles) {
    const role = mapped_roles[key];

    //Checking is Notifier Only
    if (
      role?.previlages?.notify?.length > 0 &&
      role?.previlages?.notification?.length > 0 &&
      role?.previlages?.Caseverification?.length === 0 &&
      role?.previlages?.caseRegistration?.length === 0 &&
      role?.previlages?.investigation?.length === 0
    ) {
      roleDataSecurity.isOnlyNotifier = true;
    }

    roleDataSecurity.myRole.push(role.role_id);

    //Country
    if (role.data_security?.country?.value) {
      roleDataSecurity.country.push(role.data_security.country.value);
    }

    //Disease & Forms
    role.data_security?.mappingTable?.forEach((mapTable) => {
      //Disease
      if (
        mapTable.disease?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.disease?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.disease.push(mapTable.disease?.value);
      } else {
        roleDataSecurity.disease = ["all"];
      }

      //Forms
      if (
        mapTable.form?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.form?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.form.push(mapTable.form?.value);
      } else {
        roleDataSecurity.form = ["all"];
      }
    });

    //SelectedLevel
    role?.mapped_location?.forEach((loc) => {
      Object.keys(loc)?.forEach((mapLocKey) => {
        if (
          loc[mapLocKey].value?.toLowerCase() !== "all" &&
          roleDataSecurity?.[mapLocKey]?.[0]?.value?.toLowerCase() !== "all"
        ) {
          roleDataSecurity[mapLocKey].push(loc[mapLocKey].value);
        } else {
          roleDataSecurity[mapLocKey] = ["all"];
        }
      });
    });
  }

  return roleDataSecurity;
};

export const giveMeRouteBasedOnTodoNotify = (data) => {
  if (data.notify_type) {
    //Redirecting URL for Notifier
    if (["1", "5", "6", "7", "8"].indexOf(data.notify_type) > -1) {
      return `${Routes.edit_form_notify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Verifier
    if (["2", "9", "10", "11"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_verify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Case Regsitor
    if (["3", "12"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_register_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Investigator
    if (["4"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_investigator_parent}${data.form_id}/${data.entry_id}`;
    }
  }
  return "";
};

export const giveMeReplacedNotifiyTodoText = (text = "", data = {}) => {
  //Replacing Date
  text = text.replace("<<Date>>", moment(data?.created_at).format("lll"));

  //Replace Form Name
  text = text.replace(
    "<<Form Name>>",
    data?.form_detail?.form_name ?? "a form"
  );

  let patient_name = `${data?.patient_detail?.patient_name?.title?.label ?? ""
    } ${data?.patient_detail?.patient_name?.first_name ?? ""} ${data?.patient_detail?.patient_name?.last_name?.[0] ?? ""
    }`;
  //Replace Patient Name
  text = text.replace(
    "<<Patient Name>>",
    patient_name.trim().length > 0 ? patient_name : "a patient"
  );

  //Replace Notifier Name
  text = text.replace(
    "<<Notifier User Name>>",
    data?.created_by_detail?.first_name ?? "A notifier"
  );

  //Replace Verifier Name
  text = text.replace(
    "<<Verifier User Name>>",
    data?.created_by_detail?.first_name ?? "A verifier"
  );

  //Replace Case Registor Name
  text = text.replace(
    "<<Case Registor User Name>>",
    data?.created_by_detail?.first_name ?? "A case registor"
  );

  //Replace Investigator Name
  text = text.replace(
    "<<Investigator User Name>>",
    data?.created_by_detail?.first_name ?? "A investigator"
  );

  return text;
};

export const giveMeStatusColors = (status_id) => {
  const statusColor = {
    //Investigation
    5: "#f6b26b",
    6: "green",

    //Case Registration
    7: "#f6b26b",
    8: "green",
    10: "red",
    11: "orange",

    //Case Verification
    2: "#f6b26b",
    3: "green",

    //Notified
    1: "#ffe599",
    9: "grey",
  };

  return statusColor[status_id];
};

export const giveMeCurrentStatus = (entry = {}, allStatus = []) => {
  // Checking Investigation Status
  if (
    entry?.registration_status === 8 &&
    [6, 5].indexOf(entry?.investigator_status) > -1
  ) {
    return {
      status: `${allStatus?.filter((_) => _.status_id === entry.investigator_status)?.[0]
          ?.status_name ?? ""
        }`,
      stage: "Investigation",
      color: giveMeStatusColors(entry.investigator_status),
    };
  }

  // Checking Case Registration Status
  if (
    entry?.verifier_status === 3 &&
    [7, 8, 10, 11].indexOf(entry?.registration_status) > -1
  ) {
    return {
      status: `${allStatus?.filter((_) => _.status_id === entry.registration_status)?.[0]
          ?.status_name ?? ""
        } `,
      stage: "Case Registration",
      color: giveMeStatusColors(entry.registration_status),
    };
  }

  // Checking Case Verifier Status
  if (
    entry?.notifier_status === 1 &&
    [2, 3, 10, 11].indexOf(entry?.verifier_status) > -1
  ) {
    return {
      status: `${allStatus?.filter((_) => _.status_id === entry.verifier_status)?.[0]
          ?.status_name ?? ""
        } `,
      stage: "Case Verification",
      color: giveMeStatusColors(entry.verifier_status),
    };
  }

  if ([1, 9].indexOf(entry?.notifier_status) > -1) {
    return {
      status: 'Status Unknown',
      stage: 'Unknow',
      color: 'black'
    }
  }
}

export const isPhoneNumber = (inputtxt) => {
  let phoneno = /^\d{10}$/;
  if (inputtxt?.value?.match(phoneno)) {
    return true;
  } else {
    return false;
  }
};

export const getEntityRanges = (text, mentionName, mentionKey) => {
  const indices = getIndicesOf(mentionName, text);
 
  if (indices.length > 0) {
    return indices?.map((offset, index) => ({
      // type: "mention",
      // mutability: "SEGMENTED",
      // data: {
      offset,
      length: mentionName?.length,
      // key: mentionKey
      key: index,
      
      // }
    }));
  }

  return [
    {
      // type: "mention",
      // mutability: "SEGMENTED",
      offset: 0,
      length: mentionName.length,
      key: 0,
    }
  ];
};

export const getIndicesOf = (searchStr, str, caseSensitive) => {
  let tempStr = str;
  let tempSearchStr = searchStr;
  const searchStrLen = tempSearchStr?.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;
  const indices = [];
  if (!caseSensitive) {
    tempStr = tempStr?.toLowerCase();
    tempSearchStr = tempSearchStr?.toLowerCase();
  }

  // console.log((index = tempStr?.indexOf(tempSearchStr, startIndex)) > -1)

  while ((index = tempStr?.indexOf(tempSearchStr, startIndex)) > -1) {
   
    indices?.push(index);
    startIndex = index + searchStrLen;
  }
  // console.log(indices)
  return indices;
};