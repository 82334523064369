import { FormComponents } from "../../../utils";

const fieldMentions = [
    {
      name: "Textfield",
      type: FormComponents.input_text,
      // mentionId: Math.floor(Math.random() * 100 + 1),
    },
    {
      name: "Numberfield",
      type: FormComponents.input_number,
      // mentionId: Math.floor(Math.random() * 100 + 1),
    },
    {
      name: "Date",
      type: FormComponents.input_date_picker,
      // mentionId: Math.floor(Math.random() * 100 + 1),
    },
    // {
    //   name: "Select",
    //   type: FormComponents.single_tab_select,
    //   mentionId: Math.floor(Math.random() * 100 + 1),
    // }
  ];
  
  export default fieldMentions
//   export default { '@': fieldMentions, '#': fieldTags };


// // props: {
      //   type: "text",
      //   id: Math.floor(Math.random()*10) + 1,
      //   required: true,
      //   variant: "standard",
      //   multiline: false,
      //   // onChange: () => {},
      //   value: "",
      //   error: "",
      //   helperText: ""
      // }