import React from "react";
import {
  makeStyles,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";

import ReactImageMagnify from "react-image-magnify";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControlLabel-label": {
      textTransform: "capitalize",
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const ImageWithCheckBox = ({
  option = [],
  checked = [],
  handleChange = false,
  direction = "column",
  heading,
  isReadonly = false,
  isrequired,
  errorValidation = {},
  disabled = false,
  image64 = "",
  id = "",
}) => {
  const classes = useStyles();

  const isIamChecked = (data) => {
    if (checked.indexOf(data.value) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = (value) => {
    if (checked.length === 0) {
      handleChange([...checked, value]);
    } else {
      if (checked.find((i) => i === value) !== undefined) {
        const filteredData = checked.filter((i) => i !== value);
        handleChange(filteredData);
      } else {
        handleChange([...checked, value]);
      }
    }
  };

  const imageBaseUrl = image64[0]?.file;

  return (
    <div className={classes.root}>
      <Typography variant="body1" style={{ marginBottom: "4px" }}>
        {heading}{" "}
        {isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
      <Grid container>
        <Grid item>
          <div style={{ position: "relative", zIndex: "1" }}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "image",
                  isFluidWidth: true,
                  src: `${imageBaseUrl}`,
                  sizes:
                    "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                },
                largeImage: {
                  alt: "zoomed img",
                  src: `${imageBaseUrl}`,
                  width: 1200,
                  height: 1800,
                },
                isHintEnabled: true,
              }}
            />
          </div>
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <FormGroup
              row={direction === "row" ? true : false}
              id={"formGRoup" + id}
            >
              {option &&
                option.map((data, index) => {
                  return (
                    <FormControlLabel
                      value={data.value}
                      control={
                        <Checkbox
                          id={"box" + id}
                          checked={isIamChecked(data, index)}
                          onChange={() => handleClick(data.value)}
                          color="primary"
                          disabled={(isReadonly || disabled) ?? false}
                        />
                      }
                      label={data.label}
                      labelPlacement="end"
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {errorValidation && errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
ImageWithCheckBox.propTypes = {
  option: PropTypes.array, // EX: [{value: "male", label: "male"},{value:'female',label:'female'}],
  checked: PropTypes.array, // ['female','male']
  handleChange: PropTypes.func,
  direction: "row" | "column",
  heading: PropTypes.string,
  id: PropTypes.string,
};

export default ImageWithCheckBox;
