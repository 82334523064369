import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
        {
            margin: 0,
            display: "none",
        },
        "& .MuiFormHelperText-contained": {
            margin: 0,
            marginTop: 4,
            color: "red",
        },
    },
    labelStyle: {
        fontSize: 16,
    },
    slash: {
        marginLeft: 20,
        fontSize: "30px",
    },
    errorText: {
        fontSize: "0.8rem",
    },
}));

const BmiInput = (props) => {
    const classes = useStyles(props);
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [bmi, setBmi] = useState(0);
    const [value, setValue] = useState(props?.value ?? "");

    const handleWeight = (val) => {
        setWeight(val);
    };
    const handleHeight = (val) => {
        setHeight(val);
    };
    // let obj = {
    //   weight: weight,
    //   height: height,
    //   bmi: bmi,
    // };
    // setOnChangeValue(obj);

    React.useEffect(() => {
        let bmiresult = (weight / ((height * height) / 10000)).toFixed(2);
        let results3 = bmiresult.toString();
        let results2 = height.toString();
        let results1 = weight.toString();
        let total =
            results3 + "(" + " W : " + results1 + "   /" + " H :" + results2 + " )";
        setBmi(bmiresult);

        setOnChangeValue(total);

        setValue(props?.value ?? "");
    }, [weight, height]);

    React.useEffect(() => {
        setValue(props?.value ?? "");
    }, [props?.value]);

    const setOnChangeValue = (val) => {
        setValue(val);
        props?.onChange && props.onChange(val);
    };

    const getLabel = (props) => {
        return (
            <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
                {props.label}{" "}
                {props.isrequired && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        *
                    </Typography>
                )}
            </Typography>
        );
    };

    return (
        <div className={classes.root}>
            {getLabel(props)}
            <Grid container>
                <Grid item xs={3} md={5} className={classes.gridItem}>
                    <TextField
                        type={props.type}
                        id={props.id}
                        placeholder={"Weight"}
                        // label={props.label}
                        required={props.isrequired}
                        variant={"outlined"}
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{ readOnly: props?.isReadonly ?? false }}
                        disabled={props?.disabled ?? false}
                        size="small"
                        onChange={(e) => handleWeight(e.target.value)}
                        // value={weight}
                        value={props?.value?.weight}
                        // onBlur={validations}
                        error={
                            props?.errorValidation && props?.errorValidation?.error
                                ? props?.errorValidation?.error
                                : false
                        }
                        helperText={
                            props?.errorValidation && props?.errorValidation?.errorMessage
                                ? props?.errorValidation?.errorMessage
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1} className={classes.slash}>
                    <div>/</div>
                </Grid>
                <Grid item xs={3} md={5} className={classes.gridItem}>
                    <TextField
                        type={props.type}
                        id={props.id}
                        placeholder={"Height"}
                        // label={props.label}
                        // required={props.isrequired}
                        variant={"outlined"}
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{ readOnly: props?.isReadonly ?? false }}
                        disabled={props?.disabled ?? false}
                        size="small"
                        onChange={(e) => handleHeight(e.target.value)}
                        // value={height}
                        value={props?.value?.height}
                        // onBlur={validations}
                        error={
                            props?.errorValidation && props?.errorValidation?.error
                                ? props?.errorValidation?.error
                                : false
                        }
                        helperText={
                            props?.errorValidation && props?.errorValidation?.errorMessage
                                ? props?.errorValidation?.errorMessage
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            {weight.length > 0 && height.length > 0 ? (
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.errorText}
                        >
                            BMI : {bmi}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
        </div>
    );
};
BmiInput.propTypes = {
    value: PropTypes.number,
    label: PropTypes.string,
    id: PropTypes.string,
    //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.number,
    isReadonly: PropTypes.bool,
};
BmiInput.defaultProps = {
    label: "label",
    multiline: false,
    type: "number",
};
export default BmiInput;
