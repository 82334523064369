/**
 * @author Prakash p
 */
import React from 'react'
import { withStyles, Tab, Tabs, Typography, Box, LinearProgress,Divider } from '@material-ui/core'
import {InternatlServerError,NotFoundSection,TodoListLoader} from '../../../components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Message } from '../../../utils';

const styles = theme => ({
  root: {
    // margin: 20,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    width: '100%',
    '& .MuiTabs-indicator': {
      background: '#fff !important'
    },
    '& .MuiTab-labelIcon': {
      minHeight: 48
    },
    '& .::-webkit-scrollbar': {
      height: 2
    }
  },
  tabs: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    textTransform: 'capitalize',
    fontSize: 13,
    '& .MuiTab-wrapper': {
      display: 'contents',
      '& svg': {
        margin: 6,
        fontSize: 17
      }
    }
  },
  tabsContainer: {
    '& .MuiTabs-fixed': {
      overflowX: 'auto !important'
    }
  },
  dashboardView:{
    '& .MuiTabs-flexContainer':{
      backgroundColor:'#F2EEF8',
      padding:theme.spacing(1),
      margin:theme.spacing(1),
      borderRadius:10
    },
    '& .MuiButtonBase-root':{
      flex:1,
      borderRadius:8,
      minHeight:35
    },
    '& .MuiTabs-indicator':{
      // backgroundColor:'#F2EEF8 !important',
      display:'none'
    }
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={props.padding ? 3 : ''}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}
class TabComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props?.selected ?? 0
    }
  }
  handleChange = (e, v) => {
    this.setState({
      value: v
    }, () => {
      this.props?.onTabClick(v);
    })
  }


  render() {
    const { classes, tabtitles, padding,length,loadingShimmer,error } = this.props;

    const { value } = this.state

    return (
      <div className={classes.root}>
        <div>
          {' '}
          <Tabs
            value={value}
            onChange={(e, v) => this.handleChange(e, v)}
            className={this.props.dashboardView ? classes.dashboardView : classes.tabsContainer}
          >
            {tabtitles?.map((v, i) => {
              return (
                <Tab
                  key={i}
                  label={<>
                    <Typography variant={i === value ? 'body1' : 'body2'} style={{
                      fontSize: 12,
                      color: i === value && 'rgba(17, 15, 71, 1)'
                    }}>
                      {v?.name}
                      {value === i && this.props.loading && <LinearProgress />}
                    </Typography>
                  </>}
                  icon={v?.icon ? v?.icon(i === value) : null}
                  // icon={v?.icon}
                  {...a11yProps(i)}
                  style={{ background: i === value && '#fff' }}
                  className={classes.tabs}
                />
              )
            })}
          </Tabs>
          {this.props.loadingFullWidth && <LinearProgress className={classes.progressBar} />}
          { this.props.divider && <Divider /> }
        </div>
        {tabtitles?.map((v, i) => {
          return (
            <TabPanel key={i} value={value} index={i} padding={padding}>

      {loadingShimmer && !error && length === 0 && <TodoListLoader count={5} />}
      {!loadingShimmer && error && length === 0 && <InternatlServerError message={Message.manage_form_error} onRetry={value.getTodo} />}
      {!loadingShimmer && !error && length === 0 && <NotFoundSection message={Message.todo_not_found} />}

              {this.props[v?.field]}
            </TabPanel>
          )
        })}
      </div>
    )
  }
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onTabClick: PropTypes.func,
  dashboardView:PropTypes.bool,
  divider:PropTypes.bool,
}
export default withStyles(styles)(withRouter(TabComponent))
