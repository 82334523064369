import React from 'react';
import {  withStyles,  } from '@material-ui/core';
import {Mentions} from './TextEditor';
// import { SearchOutlined } from '@material-ui/icons';
// import { Mention } from 'react-mentions';
// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import Box from '@mui/material/Box';

const styles = () => ({
    button:{
        padding: 1,
        margin: 2
    },
    variable_button :{
        background:"#3b65df",
        color:"white",
        '&:hover': {
            background:"#0059DD",
            color:"white",
        }
    },
    util_button:{
        background:"#f3a714",
        color:"white",
        '&:hover': {
            background:"#f3a714",
            color:"white",
        },
        padding: "0.05rem 1rem 0.05rem 1rem",
    },
    ButtonGroup:{
        padding:"0 0.25rem 0 0.25rem"
    },
    ButtonTitles:{
        padding: "0.5rem 0 0 0"
    }
})

class Formula extends React.Component
{
    render(){

        return (
            
            <Mentions 
                style={styles}
                save={this.props?.save}    
                cancel={this.props?.cancel}    
                formula={this.props?.formula}
                formData={this.props?.formData}
            />
            
        )
    }
}

export default withStyles(styles, {})(Formula) 