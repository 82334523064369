/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-25
 * @modify date 2021-06-29
 * @desc Top Navigation bar parent
 */

import React from 'react';
import  TopNavBar from './topNavbar';
import { actions } from "form-binder";
import { connect } from "react-redux";

class TopNavbarParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        // this.getNotifications();

        // setInterval(() => {
        //     console.log("Getting Notification Count...")
        //     this.getNotifications();
        // }, 30 * 1000);
    }

    getNotifications = () => {
        // this.props.GET_ALL_NOTIFICATIONS();
        // this.props.NOTIFICATIONS_COUNTS();
    }

    

    render() {        
        return <TopNavBar />
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(TopNavbarParent);