import React from 'react';
import { makeStyles,TextField, Grid } from "@material-ui/core";
import Select from 'react-select';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
root:{
    marginBottom:theme.spacing(2),
},
textInput:{
    minHeight:38,
    '& .MuiOutlinedInput-input':{
        padding:'2px 8px'
    }
}
}));

export const LineListSearchbar = (props) => {

    const classes = useStyles(props);

    const initialState = {
        search: "",
        selectValue:[],
    }

    const [state, setState] = React.useState(initialState);
    
    const {
        data=[],
        onValueChange=null,
        loading=false
    } = props;

    const checkUIComponents = (type) =>{
        const optionComponentsList = ["input_text","input_textarea","input_number","mobile_no"];
        if (optionComponentsList.includes(type)) {
            return true
        } else {
            return false
        }
    }


    const groupedOptions = () =>{
        let groupedOptions = [];
        data.map((_,i)=>{
            groupedOptions.push({
                label:_?.name,
                options:_?.columns?.filter(l=>l?.label)?.filter(l=>checkUIComponents(l.ui_component)) ?? []
            })
            return _
        })
        return groupedOptions
    }

    const onChange = (key,value) =>{
        
        let newState={};
        if(key === 'selectValue' && value?.length === 0){
            newState = initialState
        }
        else{
            newState = {...state,[key]:value};
        }
        
        setState(newState)

        onValueChange(newState)
    }

    return (
        <div>
            <Grid container direction="row" alignItems="center" spacing={2} className={classes.root}>
                <Grid item xs={6} md={4}>
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        isMulti
                        isLoading={loading}
                        placeholder="Select Tag"
                        options={groupedOptions()}
                        value={state.selectValue}
                        onChange={(newValue)=>onChange('selectValue',newValue)}
                    />
                </Grid>
                <Grid item xs={6} md={8}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            className: classes.textInput,
                        }}
                        disabled={state?.selectValue?.length === 0 }
                        value={state.search}
                        onChange={(e)=>onChange('search',e.target.value)}  
                    />
                </Grid>
            </Grid>
        </div>
    )
}

LineListSearchbar.propTypes = {
    data: PropTypes.array,
    loading:PropTypes.bool,
    onValueChange:PropTypes.func
}