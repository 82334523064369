/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-06-28
 * @desc Exporting all the functions from the /src/utils
 */

export * from './helperFunctions';
export * from './messages';
export * from './validations';
export * from './constants';
export * from './countryCode';

export const localStorageKeys = {
    auth_token: "auth_token",
    version: "version",
    projectName: "projectName",
    metaDataId: "metaDataId",
    userInfo : "userInfo",
    UidesignerModelerid: "UidesignerModelerid",
};