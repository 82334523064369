import React from 'react';
import { withStyles } from '@material-ui/core';
import  LocationLevel  from './locationLevel'
import { actions } from 'form-binder';
import { connect } from "react-redux";


const styles = ((theme) => ({
    root: {
        flex: 1
    }
}))
class LocationLevelParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.getAllCountries();
    }

    getAllCountries = () =>{
        this.props.GET_ALL_COUNTRIES({});
    }

    render() {

        const {
                classes,
                getAllCountries,
                label,
                isReadonly,
                isrequired,
                handleChange,
                value,
                errorValidation,
                disabled
             } = this.props;

        return <div className={classes.root}>
            {
            !getAllCountries.loading && !getAllCountries.error && 
            <LocationLevel  
                countryData={getAllCountries}
                label={label}
                value={value}
                handleChange={handleChange}
                isReadonly={isReadonly}
                isrequired={isrequired}
                errorValidation={errorValidation}  
                disabled={disabled}
            />
            }
        </div>
    }
}

const mapStateToProps = (state) => ({
    getAllCountries: state?.countrySlice?.getAllCountries,
});

export default connect(mapStateToProps, actions)((withStyles(styles)(LocationLevelParent)));

