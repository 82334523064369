import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
        {
            margin: 0,
            display: "none",
        },
        "& .MuiFormHelperText-contained": {
            margin: 0,
            marginTop: 4,
            color: "red",
        },
    },
    labelStyle: {
        fontSize: 16,
    },
    slash: {
        marginLeft: 20,
        fontSize: "30px",
    },
}));

const BpInput = (props) => {
    const classes = useStyles(props);
    const [a, setA] = useState();
    const [b, setB] = useState();
    const [value, setValue] = useState(props?.value ?? "");
    const [error, setError] = useState();
    const [hidden, setHidden] = useState(true);

    const handleA = (e) => {
        setA(e);
    };
    const handleB = (e) => {
        setB(e);
        setHidden(false);
    };

    React.useEffect(() => {
        if (a < b) {
            setError(
                "Systolic pressure (SP) is greater than Diastolic pressure (DP)!"
            );
        } else {
            calculatesysdi();
        }

        setOnChangeValue(error);

        setValue(props?.value ?? "");
    }, [a, b, props?.value]);

    const setOnChangeValue = (val) => {
        setValue(val);
        props?.onChange && props.onChange(val);
    };
    const calculatesysdi = () => {
        var pp = a - b;
        var fma = 2 * b;
        var sma = fma + a;
        var map = Math.round(sma / 3);
        setError("Mean Artereial Pressure (MAP) " + " = " + map);
        var Pp = "Pulse Pressure (PP) = ";
        var status = "Blood pressure status : ";
        if (a < 120 && b < 80) {
            setError(status + "Normal Blood Pressure");
        } else if ((a > 119 && a < 140) || (b > 79 && b < 90)) {
            setError(status + "Pre-hypertension");
        } else if ((a > 139 && a < 160) || (b > 89 && b < 100)) {
            setError(status + "Stage I high blood pressure-hypertension");
        } else if ((a > 159 && a < 181) || (b > 99 && b < 111)) {
            setError(status + "Stage II high blood pressure-hypertension");
        } else if (a > 180 || b > 110) {
            setError(
                status + "Hypertensive crisis(where emergency care is required)"
            );
        }
        setError(Pp + pp);
    };

    const getLabel = (props) => {
        return (
            <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
                {props.label}{" "}
                {props.isrequired && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        *
                    </Typography>
                )}
            </Typography>
        );
    };

    return (
        <div className={classes.root}>
            {getLabel(props)}
            <Grid container>
                <Grid item xs={3} md={5} className={classes.gridItem}>
                    <TextField
                        type={"number"}
                        id={props.id}
                        placeholder={"SP"}
                        // label={props.label}
                        required={props.isrequired}
                        variant={"outlined"}
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{ readOnly: props?.isReadonly ?? false }}
                        disabled={props?.disabled ?? false}
                        size="small"
                        multiline={props.multiline}
                        onChange={(e) => handleA(e.target.value)}
                        value={props?.value?.a}
                        // onBlur={validations}
                        error={
                            props?.errorValidation && props?.errorValidation?.error
                                ? props?.errorValidation?.error
                                : false
                        }
                        helperText={
                            props?.errorValidation && props?.errorValidation?.errorMessage
                                ? props?.errorValidation?.errorMessage
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1} className={classes.slash}>
                    <div>/</div>
                </Grid>
                <Grid item xs={3} md={5} className={classes.gridItem}>
                    <TextField
                        type={"number"}
                        id={props.id}
                        placeholder={"DP"}
                        // label={props.label}

                        required={props.isrequired}
                        variant={"outlined"}
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{ readOnly: props?.isReadonly ?? false }}
                        disabled={props?.disabled ?? false}
                        size="small"
                        multiline={props.multiline}
                        onChange={(e) => handleB(e.target.value)}
                        value={props?.value?.b}
                        // onBlur={validations}
                        error={
                            props?.errorValidation && props?.errorValidation?.error
                                ? props?.errorValidation?.error
                                : false
                        }
                        helperText={
                            props?.errorValidation && props?.errorValidation?.errorMessage
                                ? props?.errorValidation?.errorMessage
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <div hidden={hidden}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography
                            variant="caption"
                            component="div"
                        // className={classes.errorText}
                        >
                            bp : {error}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
BpInput.propTypes = {
    value: PropTypes.number,
    label: PropTypes.string,
    id: PropTypes.string,
    //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.number,
    isReadonly: PropTypes.bool,
};
BpInput.defaultProps = {
    label: "label",
    multiline: false,
    type: "number",
};

export default BpInput;
