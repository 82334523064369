import React from "react";
import {
  List,
  makeStyles,
  ListItem,
  // ListItemIcon,
  // ListItemSecondaryAction,
  ListItemText,
  Divider
} from "@material-ui/core";
import PropTypes from "prop-types";
import { giveMeReplacedNotifiyTodoText, giveMeRouteBasedOnTodoNotify } from "../../utils";

// store
import { actions } from 'form-binder'
import { useDispatch, useSelector } from 'react-redux'

import { AlertProps } from "../../utils";

import { AlertContext } from '../.././contexts';
import { Typography } from "@material-ui/core";

export * from './loading';
export * from './notificationLoader';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 35
    }
  },
  notificationReason: {
    borderLeft: `4px solid ${theme.palette.ternary.light}`,
    background: `${theme.palette.background.default}`,
    paddingLeft: 8,
    marginTop: 4,
    marginBottom: 4,
  }
}));

export const NotificationList = (props) => {

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const classes = useStyles(props);
  const alert = React.useContext(AlertContext);

  const getAllNotifications = useSelector(
    state => state?.notificationSlice?.getAllNotifications,
  )

  // Store Action's list
  const { NOTIFICATION_READ_STATUS, GET_ALL_NOTIFICATIONS, NOTIFICATIONS_COUNTS } = actions

  const callFunction = () => {
    dispatch(GET_ALL_NOTIFICATIONS());
    dispatch(NOTIFICATIONS_COUNTS());
  };

  const handleToggle = (item, isCheck) => () => {
    window.open(giveMeRouteBasedOnTodoNotify(item));

    let __params = {
      notificationStatusId: item?.notification_id,
    }

    Promise.resolve(dispatch(NOTIFICATION_READ_STATUS(__params))).then(res => {

      let resStatus = res.payload.error;

      if (!resStatus) {
        callFunction();
      } else {
        let statusMessage = "Notification Read Status Updated Failed Check your Internet Connection !";

        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
          msg: resStatus ? statusMessage : '',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })

      }

    });

  };


  return (
    <div className={`${classes.root}`}>
      <List className={classes.root}>

        {getAllNotifications?.data?.map((_, index) => {
          return (
            <div key={_?.notification_id}>
              <ListItem
                key={_?.notification_id}
                role={undefined}
                dense
                button
              >
                <ListItemText
                  id={index}
                  primary={<Typography variant="body2" >
                    {giveMeReplacedNotifiyTodoText(_?.notification, _)}
                    {_?.reason && <Typography className={classes.notificationReason} variant="subtitle2">
                      {_?.reason}
                    </Typography>
                    }
                  </Typography>
                  }
                  secondary={_?.notification_id ? "View the Notification" : ""}
                  onClick={handleToggle(_, false)}
                  style={{
                    textDecorationLine: 'none',
                    color: _?.is_readed ? 'grey' : 'black',
                  }}
                />
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
    </div>
  );
};

NotificationList.propTypes = {
  data: PropTypes.array,
  statusChange: PropTypes.func,
  onEditIconClicked: PropTypes.func,
  onDeleteIconClicked: PropTypes.func,
};
