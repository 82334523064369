/**
 * @author Prakash p
 */
import React from 'react'
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Link,
  Tooltip
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import moment from 'moment';
// import { ToggleButtonComponent } from '../../';
import { withAllContexts } from '../../../HOCs';
import { CustomTableFormRender, CustomTableFixedRowFormRender, OrdinaryTableForRender } from '../../formComponents'
import { AlertProps, giveMeCurrentStatus } from '../../../utils'
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const styles = theme => ({
  root: {
    boxShadow: '0px 5px 23px #00000014',
    padding: 0,
    '& .MuiTableContainer-root': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      borderTopLeftRadius: 0,
      boxShadow: 'none'
    },
    '& .MuiTableCell-body': {
      [theme.breakpoints.down('xs')]: {
        minWidth: 150
      }
    }
  },
  nodata: {
    textAlign: 'center',
    fontWeight: 500,
    padding: theme.spacing(4)
  },
  titleText: {
    fontSize: 12,
    color: '#888790'
  },
  bodyRowText: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  defaultStats: {
    textTransform: 'uppercase',
    width: 'fit-content'
  }
})

class TableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 5,
      newPage: 0,
      toggleButtonArray: [],
    }
  }


  viewTable = (ui_component, tableData, tableScheme, title) => {

    const { dialog } = this.props;

    dialog.setDialog({
      ...dialog,
      open: true,
      title: title,
      body: this.giveMeComponent(ui_component, tableData, tableScheme),
      positiveBtn: 'Ok',
      negativeBtn: "",
      onOk: () => this.closeDialog(),
    })
  }

  closeDialog = () => {
    const { dialog } = this.props;
    dialog.setDialog({
      ...dialog, open: false
    })
  }

  giveMeComponent = (ui_component, tableData, tableScheme) => {
    if (ui_component === 'custom_table') {
      return (
        <CustomTableFormRender
          data={{
            table_scheme: tableScheme
          }}
          tableData={tableData}
          isReadonly={true}
        />
      )
    }
    else if (ui_component === 'custom_table_2') {
      return (
        <CustomTableFixedRowFormRender
          data={{
            table_scheme: tableScheme
          }}
          tableData={tableData}
          isReadonly={true}
        />
      )
    }
    else if (ui_component === "ordinary_table") {
      return (
        <OrdinaryTableForRender
          data={{
            table_scheme: tableScheme
          }}
          tableData={tableData}
          isReadonly={true}
        />
      )
    }
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      newPage: 0
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      newPage: newPage
    })
  }



  giveMeStatus = () => {
    if (this.props.location.pathname === "/case_registrations") {
      return "registration_status"
    } else if (this.props.location.pathname === "/verifications") {
      return "verifier_status"
    } else if (this.props.location.pathname === '/investigations') {
      return "investigator_status"
    }
    else if (this.props.location.pathname === '/notifications') {
      return "notifier_status"
    }
  }

  onButtonClick = (colData, rowData) => {
    // 
    switch (colData?.actionType) {
      case "route":
        this.props.history.push({
          pathname: colData?.link + "/" + rowData?.entry_id,
          state: colData?.state ?? {}
        });
        break;

      case "delete":
        this.props.deleteEntry(rowData);
        break;

      default:
        break;
    }
  }

  
  onStatusToogleBtnSelected = (status, entry) => {

    this.props?.onToogleSelected(status, entry)

    if (status === 4 || status === 10 || status === 11) {
    } else {
      if (status === 3) {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Verification has been done Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      } else if (status === 8) {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Registration has been done Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      } else if (status === 1) {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Notification has been done Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      }
      else {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Status updated !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      }

    }

  }

  giveMeFieldToShow = (v, l) => {

    if (Array.isArray(v?.[l?.field])) {
      //If the value is array then retrun the string.
      return v?.[l?.field]?.map(_ => {
        //If the array child is the label,value pare then return only label
        if (_?.label) {
          return _?.label
        }
        return _;
      }).toString()
    }

    else if (v?.[l?.field]?.label) {
      //If the value is object with value,label pair then return label only
      return v?.[l?.field]?.label;
    }

    else if (v?.[l?.field]?.address) {
      //If the value is a location object then return the formated address
      return `${v?.[l?.field].address} (${v?.[l?.field]?.latitude}, ${v?.[l?.field]?.longitude})`;
    }

    else if (v?.[l?.field]?.tableData) {
      //If the value is the table type,
      return <Link
        component="button"
        variant="body2"
        onClick={() => {
          this.viewTable(l?.ui_component, v?.[l?.field]?.tableData, v?.[l?.field]?.table_scheme, l?.title)
        }}
      >
        {`View ${l?.title}`}
      </Link>
    }

    else if (v?.[l?.field]?.number) {
      return `${v?.[l?.field]?.phoneCode} ${v?.[l?.field]?.number}`
    }

    else if (v?.[l?.field]?.country) {
      return `${v?.[l?.field]?.country?.value}`
    }

    else if (v?.[l?.field]?.dob) {
      return `${v?.[l?.field]?.dob}, ${v?.[l?.field]?.age}y`
    }

    else {
      //If nothing mathes the above conditions
      return v?.[l?.field]
    }
  }

  render() {

    const { classes, data, loading, hideShadow = false } = this.props;

    const { newPage, rowsPerPage } = this.state;

    return (
      <div
        className={classes.root}
        style={{ boxShadow: hideShadow && 'none' }}
      >
        <TableContainer component={Paper} style={{ height: this?.props?.height ? "calc(100vh - 399px)" : "100%" }}>

          <Table stickyHeader aria-label="sticky table" >

            <TableHead >

              <TableRow>
                {data?.column?.map((v, i) => {
                  return <TableCell key={i} variant="head" style={{ width: v?.width ?? '', backgroundColor: '#fff' }}>
                    <Typography variant="body1" className={classes.titleText}>
                      {v.title}
                    </Typography>
                  </TableCell>
                })}
              </TableRow>

            </TableHead>

            <TableBody>
              {data?.row
                ?.slice(
                  newPage * rowsPerPage,
                  newPage * rowsPerPage + rowsPerPage
                )
                .map((v, index) => {
                  return (

                    <TableRow key={index}>
                      {data?.column?.map((l, i) => {

                        switch (l.type) {

                          case 'sno':
                            return <TableCell key={i}>

                              <Typography variant="body2" className={classes.bodyRowText}>
                                {index + 1}
                              </Typography>

                            </TableCell>

                          case 'date':
                            return <TableCell key={i}>

                              <Typography variant="body2" className={classes.bodyRowText}>
                                {moment(v[l?.field]).format('lll')}
                              </Typography>

                            </TableCell>

                          case 'button':
                            return <TableCell key={i}>

                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => this.onButtonClick(l, v)}
                                size="small">
                                {l.title}
                              </Button>

                            </TableCell>

                          case 'action':
                            return <TableCell key={i}>
                              <Tooltip title="Delete Notification">
                                <IconButton
                                  onClick={() => this.onButtonClick(l, v)}
                                  size="small">
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </TableCell>

                          case 'change_status':
                            const statusData = giveMeCurrentStatus(v, this.props.allStatus);
                            return <TableCell key={i}>
                              {/* <Tooltip arrow title={`Form is in ${data?.defaultStatus?.label} state`} placement="top-start"> */}
                              <Typography
                                variant="body1"
                                style={{color: statusData.color}}
                                className={classes.defaultStats}>
                                {statusData.status}
                              </Typography>
                              <Typography variant="caption">{`Stage: ${statusData.stage}`}</Typography>
                              {/* </Tooltip> */}
                            </TableCell>


                          case 'patient_name':
                            return <TableCell key={i}>
                              <Tooltip
                                // arrow
                                placement="top-start"
                                title={`${v?.[l?.field]?.title?.label} ${v?.[l?.field]?.first_name} ${v?.[l?.field]?.middle_name ?? ""} ${v?.[l?.field]?.last_name ?? ""}`}>
                                <Typography variant="body2">
                                  {`${v?.[l?.field]?.title?.label ?? ""} ${v?.[l?.field]?.first_name ?? ""} ${v?.[l?.field]?.last_name?.[0] ?? ""}`}
                                </Typography>
                              </Tooltip>
                            </TableCell>

                          case 'age_gender':
                            return <TableCell key={i}>
                              <Typography variant="body2">
                                <Typography variant="body2" style={{ marginLeft: 8 }}>{`${v?.gender ?? ""}, ${v?.dob_age?.age ?? "-"}y`}</Typography>
                              </Typography>
                            </TableCell>

                          case 'contact':
                            return <TableCell key={i}>
                              <Typography variant="body2">
                                {`${v?.mobile?.phoneCode} ${v?.mobile?.number},`}
                              </Typography>
                              {v?.email && <Typography variant="body2">
                                {`${v?.email}`}
                              </Typography>}
                            </TableCell>

                          default:
                            return <TableCell key={i}>
                              <Typography variant="body2" className={classes.bodyRowText}>
                                {this.giveMeFieldToShow(v, l)}
                              </Typography>
                            </TableCell>

                        }

                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          {!loading && (data?.row?.length === 0 || !data) && (
            <Typography className={classes.nodata}>{this.props?.notFoundMessage ?? "No data"}</Typography>
          )}

        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data?.row?.length}
            rowsPerPage={rowsPerPage}
            page={newPage}
            onChangePage={(e, v) => this.handleChangePage(e, v)}
            onChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)}
          />
        )}
      </div>
    )
  }
}
export default withStyles(styles)(withRouter(withAllContexts(TableComponent)))
