import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
    height: "100%",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      cursor: "pointer",
      zIndex: 10000,
    },
    "& .MuiButton-root": {
      top: "80px",
    },
    "& .MuiTypography-colorPrimary": {
      marginTop: theme.spacing(11),
    },
  },
  drap: {
    position: "relative",
    height: 180,
    borderRadius: 6,
    textAlign: "center",
    background: theme.palette.background.paper,
    border: "2px dashed #48AABF",
  },
}));

export const Upload = (props) => {
  const classes = useStyles(props);
  // console.log(props, "props")
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <div className={classes.drap}>
          <div className={classes.root}>
            {props?.single && (
              <input
                id={"input1" + props?.id}
                type="file"
                onChange={(e) => props.onChange(e)}
                disabled={props.disabled}
              />
            )}

            {!props?.single && (
              <input
                id={"input2" + props?.id}
                type="file"
                multiple
                onChange={(e) => props.onChange(e)}
                disabled={props.disabled}
              />
            )}
            <Button style={{ marginTop: "-20px" }} id={"add" + props?.id}>
              {/* <img src="/images/Group 4627.svg" alt="No" /> */}
              <AddIcon style={{ color: "#48AABF" }} />
            </Button>
            <Typography variant="subtitle2" color="primary">
              Click to browse and upload
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
