/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-06-17
 * @desc The private route check wheather the user is logged in or not and also check
 * wheather the request route from the user is accessible to them or not using the Access(role,path)
 * function then allow to the particular route or else it will redirect to login page.
 */

import React from "react";
import { Redirect, Route } from "react-router-dom";
const PrivateRoute = (routeprops) => {
  let { component: Component, path, ...rest } = routeprops;
  const isAuthenticated = (router) => {
    try {
      if (sessionStorage.getItem('payload')) {
        return true;
      } else {
        return false;
      }

    } catch (e) {
      return false;
    }
  };
  return (
    <Route
      {...rest} render={(props) => Boolean(sessionStorage.getItem('payload')) ? (
        <Component {...props} />
      ) : (
        <Redirect to={"/"} />
      )
      }
    />
  );
};
export default PrivateRoute;
