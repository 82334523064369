import React from "react";
import { ForgotPassword } from './forgotPasswordScreen';
import { actions } from "form-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() { }

  render() {
    return (<ForgotPassword />);
  }
}

const mapStateToProps = (state) => ({
  auth: state?.authSlice?.login,
});


export default connect(
  mapStateToProps,
  actions
)(withRouter(Forgot));

