import React from 'react'
import {
  makeStyles,
  Avatar,
  Badge,
  Button,
  Popover,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { NotificationDrawer } from '../../../components'
import IconButton from '@material-ui/core/IconButton';
import { Notification } from '../../../assets'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { LocalStorageKeys, DrawerProps } from '../../../utils';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';

// import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DrawerContext } from '../../../contexts';
import { useSelector } from 'react-redux';
import { Router, useHistory , withRouter} from 'react-router-dom'
import { Routes } from '../../../router/routes'

// import Popover from '@material-ui/core/Popover';


const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1
  },
  badge: {
    '& .MuiBadge-badge': {
      margin: 4,
      background: theme.palette.ternary.main,
      border: `0.2px solid ${theme.palette.ternary.contrastText}`
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 66,
    justifyContent: 'center'
  },
  logout: {
    padding: 10
  },
  titleContainer: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  menuIcon: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  profilePic: {
    width: 40,
    height: 40,
    borderRadius: 100,
    border: `1px solid ${theme.palette.common.white}`,
    marginRight: 12,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: "0px",
    paddingLeft: "30px",

    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-noWrap': {
        display: 'none'
      },
      '& .MuiAvatar-circle': {
        marginRight: 0
      },
      padding: '0px 15px',
    }
  },
  separator: {
    backgroundColor: '#ffffff5c',
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '0.1px'
    }
  },
  rightSideMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  notifyIcon: {
    marginRight: 30,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 25
    }
  },
  logOutView: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16
    }
  },
  subTitle: {
    opacity: 0.6
  },
  button: {
    color: theme.palette.common.white,
    textTransform: 'capitalize'
  },
  notificationSvg: {
    width: 47,
    height: 47,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const TopNavBar = (props) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { data } = useSelector(
    state => state?.authSlice?.login
  )

  //Code for Side Navbar open
  /*
  const [state, setState] = React.useState({
    openSideNavBar: false
  })
  */
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleLogout = () => {
    sessionStorage.clear();
    // window.location.reload();
    props.history.push('/')
  }

  // const [anchorEl, setAnchorEl] = React.useState(null);

  const NotificationsCounts = useSelector(
    state => state?.notificationSlice?.NotificationsCounts,
  )

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawerContext = React.useContext(DrawerContext);

  const onActionCompleted = () => {
    onDrawerClosed();
  }

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false
    })
  }


  // viewNotifications
  const viewNotifications = () => {

    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <NotificationDrawer />,
      onClose: () => onActionCompleted()
    })
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //Code for Side Navbar open
  /*
  const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar
    })
  }
  */


  // use media query hooks for Laptop View
  /*
  function LapTopView() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    if (matches) {
      return (
        <Button
          variant='text'
          className={classes.button}
          endIcon={
            <Avatar
              src='/images/sign-out.svg'
              className={classes.logout}
            />
          }
          onClick={handleLogout}
        >
          {`Log Out`}
        </Button>
      )
    }
    else
      return null
  }
  */

  // use media query hooks for Mobile View
  /*
  function MobileView() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    if (matches) {
      return <Button aria-describedby={id} onClick={handleClick}>
        // Open Popover
        < ArrowDropDownIcon style={{ color: "white" }} />
      </Button>
    }
    else
      return null
  }
  */


  return (
    <div className={classes.grow}>
      <AppBar position='static' className={classes.appBar}>

        <Toolbar className={classes.toolBar}>
          {/* //Code for Side Navbar open */}
          {/* <IconButton className={classes.menuIcon} onClick={toogleSideNavBar}>
            <MenuIcon htmlColor='white' />
          </IconButton> */}

          <div className={classes.titleContainer}>



            <div style={{ display: "flex", justifyContent:"space-between" }}>
              {/* Logo */}
              {/* <div> */}
                {/* <img src="" alt="logo" style={{
                  height: "60px",
                  width: "90px",
                  "object-fit": "contain",
                  marginRight: "10px"

                }} /> */}
              {/* </div> */}

              {/* Version */}
              {/* <div style={{display:"flex", justifyContent:"space-between"}}> */}
              <div>
                <Typography variant={"h6"} >
                  {"Form Configurators"}
                </Typography>
                <Typography variant='caption' >
                  {`v${localStorage.getItem(LocalStorageKeys.version)}`}
                </Typography>
              </div>
              <div>
                <Typography variant={"h6"} >
                  {"Admin"}
                  <Button onClick={handlePopOver} >                 
                     <ExpandMoreIcon />
                  </Button>                
                 </Typography>
               
                
                {/* <ChevronRightIcon color={"primary"} /> */}
              
              </div>
              {/* </div> */}
            </div>

          </div>

          <div className={classes.rightSideMenu}>
            {/* <IconButton color='inherit' className={classes.notifyIcon} onClick={viewNotifications}>
              <Badge badgeContent={NotificationsCounts?.data?.length} className={classes.badge}>
                <div className={classes.notificationSvg}>
                  <Notification />
                </div>
              </Badge>
            </IconButton> */}

            {/* <Divider
              orientation='vertical'
              flexItem
              className={classes.separator}
            /> */}

            {/* <div className={classes.rowView}>
              <Avatar className={classes.profilePic} src={props?.profilePic}>
                {data?.first_name?.[0] ?? 'P'}
              </Avatar>

              <div>
                <Typography variant={'body1'} noWrap>
                  {data?.first_name}
                </Typography>

                <Typography
                  className={classes.subTitle}
                  variant='caption'
                  color='textSeondary'
                  noWrap
                >
                  {data?.mapped_roles?.[0]?.role_name}
                </Typography>
              </div>
            </div> */}

                {/* Open Popover */}
            {/* <div className={classes.logOutView}>

              <Button aria-describedby={id} onClick={handleClick}>
                < ArrowDropDownIcon style={{ color: "white" }} />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div >
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={handleLogout}>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </div>
              </Popover>
            </div> */}

          </div>

          {/* Side Nav Bar drawer */}
          {/* <Drawer
            open={state.openSideNavBar}
            variant={'temporary'}
            anchor='left'
            onClose={toogleSideNavBar}
          >
            <div style={{ width: 240 }}>
              <SideNavBar isMobile={true} />
            </div>
          </Drawer> */}

        </Toolbar>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* <Typography className={classes.typography}>The content of the Popover.</Typography> */}
        <div >
        <Button variant="contained"   onClick={handleLogout}> <ExitToAppIcon/> {'\u00A0'} Logout</Button>
        </div>
      </Popover>
    </div>
  )
}

export default withRouter(TopNavBar);