/**
 * @author Prakash p
 */
import React from 'react'
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Typography,
     Button,
  //    Link,
  //    Tooltip
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
// import { Routes } from '../../../router/routes';
import moment from 'moment';
// import { ToggleButtonComponent } from '../../';
import { withAllContexts } from '../../../HOCs';
//  import { CustomTableFormRender, CustomTableFixedRowFormRender } from '../../formComponents'
//  import { AlertProps } from '../../../utils'

const styles = theme => ({
  root: {
    boxShadow: '0px 5px 23px #00000014',
    padding: 0,
    '& .MuiTableContainer-root': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      borderTopLeftRadius: 0,
      boxShadow: 'none'
    },
    '& .MuiTableCell-body': {
      [theme.breakpoints.down('xs')]: {
        minWidth: 150
      }
    }
  },
  nodata: {
    textAlign: 'center',
    fontWeight: 500,
    padding: theme.spacing(4)
  },
  titleText: {
    fontSize: 12,
    color: '#888790'
  },
  bodyRowText: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  defaultStats: {
    textTransform: 'uppercase',
    width: 'fit-content'
  }
})

class TableComponentV2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 5,
      newPage: 0,
    }
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      newPage: 0
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      newPage: newPage
    })
  }
  
     onButtonClick = (data) => {
       // eslint-disable-next-line
           this.props.history.push(`${'/notify/edit/'}`+`${data?.form_id}`+`${'/'}`+`${data?.entry_id}`);
       }
     

  render() {

    const { classes, data, loading, hideShadow = false } = this.props;
    const { newPage, rowsPerPage } = this.state;

    return (
      <div
        className={classes.root}
        style={{ boxShadow: hideShadow && 'none' }}
      >
        <TableContainer component={Paper} style={{ height: this?.props?.height ? "calc(100vh - 310px)" : "100%" }}>

          <Table stickyHeader aria-label="sticky table" >

            <TableHead >

              <TableRow>
                {["S.no", "Created at", "Disease Name", "Form Name", "View Form"]?.map((v, i) => {
                  return <TableCell key={i} variant="head" style={{ width: v?.width ?? '', backgroundColor: '#fff' }}>
                    <Typography variant="body1" className={classes.titleText}>
                      {v}
                    </Typography>
                  </TableCell>
                })}
              </TableRow>

            </TableHead>

            <TableBody>
              {data?.data
                ?.slice(
                  newPage * rowsPerPage,
                  newPage * rowsPerPage + rowsPerPage
                )
                .map((v, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell key={v}>
                        <Typography variant="body2" className={classes.bodyRowText}>
                          {index + 1}
                        </Typography>
                      </TableCell>

                      <TableCell key={v}>
                        <Typography variant="body2" className={classes.bodyRowText}>
                          {moment(v?.created_at).format('lll')}
                        </Typography>
                      </TableCell>

                      <TableCell key={v}>
                        <Typography variant="body2" className={classes.bodyRowText}>
                          {v?.mapped_diseases[0]?.disease_name ? v?.mapped_diseases[0]?.disease_name : "Disease Not Found !"}
                        </Typography>
                      </TableCell>

                      <TableCell key={v}>
                        <Typography variant="body2" className={classes.bodyRowText}>
                          {v?.form_detail[0]?.form_name}
                        </Typography>
                      </TableCell>

                      <TableCell key={v}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={()=>this?.onButtonClick(v)}
                          size="small">
                          {"view"}
                        </Button>

                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          {!loading && (data?.data?.length === 0 || !data?.data) && (
            <Typography className={classes.nodata}>{this.props?.notFoundMessage ?? "No data"}</Typography>
          )}

        </TableContainer>
        {data?.data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data?.data?.length}
            rowsPerPage={rowsPerPage}
            page={newPage}
            onChangePage={(e, v) => this.handleChangePage(e, v)}
            onChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)}
          />
        )}
      </div>
    )
  }
}
export default withStyles(styles)(withRouter(withAllContexts(TableComponentV2)))
