/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-03-16
 * @desc The first component of the application, all the required context and routes are used here 
 * to setup the application.
 */

import React from "react";

import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppDrawer from "./App.drawer";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";

import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";

// Reactive Core Setup
import { store as UIstore } from "form-binder";
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={UIstore}>
      <AppAuth>
        <AppTheme>
          <AppErrorBoundary>
            <CssBaseline />
            <AppAlert>
              <AppDialog>
                <AppBackdrop>
                  <AppDrawer>
                    <RouterApp />
                  </AppDrawer>
                </AppBackdrop>
              </AppDialog>
            </AppAlert>
          </AppErrorBoundary>
        </AppTheme>
      </AppAuth>
    </Provider>
  );
}

export default App;
