/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-23
 * @modify date 2021-02-23
 * @desc Filter Builder
 */

import React from 'react';
import { Grid, Typography, withStyles, Divider, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { actions } from 'form-binder';
import { connect } from 'react-redux';
import { Select } from '../'

const styles = (theme) => ({
    root: {
        height: '100vh'
    },
    header: {
        padding: '10px 20px'
    },
    gridItem: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
})

class FilterBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icd10: this.props?.icd10 ?? [],
            classification: this.props?.classification ?? [],
        }
    }

    componentDidMount() {
        this.getAllICDCode();
        this.getAllClassification();
    }


    getAllICDCode = () => {
        if (this.props?.icd10Options?.data?.length === 0)
            this.props.GET_ALL_ICD10CODE();
    }

    getAllClassification = () => {
        if (this.props?.classificationOptions?.data?.length === 0)
            this.props.GET_ALL_DISEASES_CLASSIFICATION();
    }

    onChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    onRestClicked = () => {
        this.setState({
            icd10: [],
            classification: []
        }, () => this.props.applyFilter(this.state, false))
    }

    onApplyClicked = () => {
        this.props.applyFilter(this.state, true);
    }


    render() {

        const {
            classes,
            title = 'Filter',
            showClassification = false,
            showIcd10 = false,
            icd10Options,
            classificationOptions,
        } = this.props;

        const {
            icd10,
            classification
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction='column'
                    justify='flex-start'
                    alignItems='stretch'
                >
                    <Grid
                        className={classes.header}
                        item
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography>
                                {title}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="text"
                                onClick={this.onRestClicked}
                                size="small">
                                {"Reset"}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={this.onApplyClicked}
                            >
                                {"Apply"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />


                    {showIcd10 && <Grid item className={classes.gridItem}>
                        <Select
                            name="icd10"
                            label={"ICD 10 code"}
                            multiple
                            value={icd10}
                            handleselect={(data) => this.onChange('icd10', data)}
                            option={icd10Options?.data ?? []}
                            loading={icd10Options?.loading}
                        />
                    </Grid>}

                    {showClassification && <Grid item className={classes.gridItem}>
                        <Select
                            name="classification"
                            label={"Classification"}
                            multiple
                            value={classification}
                            handleselect={(data) => this.onChange('classification', data)}
                            option={classificationOptions?.data ?? []}
                            loading={classificationOptions?.loading}
                        />
                    </Grid>}
                </Grid>
            </div>
        )
    }
}

FilterBuilder.propTypes = {
    title: PropTypes.string,
    showIcd10: PropTypes.bool,
    icd10: PropTypes.object,
    showClassification: PropTypes.bool,
    classification: PropTypes.object,

    applyFilter: PropTypes.func
}

const mapStateToProps = state => ({
    icd10Options: state?.diseaseSlice?.getAllICD10Code,
    classificationOptions: state?.diseaseSlice?.getAllDiseasesClassification
})

export default connect(
    mapStateToProps,
    actions
)(withStyles(styles)(FilterBuilder));