import React from 'react';
import { Switch } from "@material-ui/core";
import PropTypes from 'prop-types';

export const SwitchComponent = (props) => {
    
    const [state, setState] = React.useState({
        switch:props?.checked ?? false
      });
    
      const handleChange = (event) => {
            setState({ ...state, [event.target.name]: event.target.checked });
            props?.onChange()
      };

    
    return (
       <div>
            <Switch
                checked={state.switch}
                onChange={handleChange}
                color="secondary"
                name="switch"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
       </div>
    )
}

SwitchComponent.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
}