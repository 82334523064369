import React from 'react';
import { ListForms } from './manageForms';
import { withNavBars, withSubNavBar } from '../../HOCs';
import { withStyles } from '@material-ui/core';
import { actions } from "form-binder";
import { connect } from "react-redux";
import { giveMeRoleDataSecurity } from '../../utils';


const styles = ((theme) => ({
    root: {

    }
}))
class ManageFormsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: "all",
        }
    }

    handleSortChange = (sort) => {
        this.setState({
            sort
        }, () => {
            
        })
    }

    componentDidMount() {
        
        this.getFormTypes();
    }

    getFormTypes = () => {
        this.props.GET_ALL_FORM_TYPES();
    }


    render() {

        const { classes } = this.props;

        return <div className={classes.root}>
            <ListForms
                // {...getAllForms}
                // getForms={this.getForms}
                sort={this.state.sort}
                handleSortChange={this.handleSortChange}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    
    userData: state?.authSlice?.login?.data ?? {},
});

export default connect(
    mapStateToProps,
    actions
)(withNavBars(
    withSubNavBar(
        withStyles(styles)(ManageFormsParent)
    )
));

