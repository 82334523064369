import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  root: {
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
}));
const TemperatureInput = ({ required, ...props }) => {
  const classes = useStyles(props);
  const [temperature, setTemperature] = useState("");
  const [value, setValue] = useState(props?.value ?? "");
  const onChange = (e) => {
    setTemperature(e);
    setOnChangeValue(e);
  };
  const helpText = () => {
    if (temperature < 90) {
      return "low temperature";
    }
    if (temperature > 100) {
      return "high temperature";
    }
  };
  React.useEffect(() => {
    setValue(props?.value ?? "");
  }, [props?.value]);

  const setOnChangeValue = (val) => {
    // debugger;
    setValue(val);
    props?.onChange && props.onChange(val);
  };

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <TextField
          type={props.type}
          id={props.id}
          // label={props.label}
          required={props.isrequired}
          variant={"outlined"}
          fullWidth
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ readOnly: props?.isReadonly ?? false }}
          disabled={props?.disabled ?? false}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          helperText={temperature.length > 0 ? helpText() : ""}
          value={value}
          // onBlur={validations}
          error={
            props?.errorValidation && props?.errorValidation?.error
              ? props?.errorValidation?.error
              : false
          }
          // // helperText={
          //   props?.errorValidation && props?.errorValidation?.errorMessage
          //     ? props?.errorValidation?.errorMessage
          //     : ""
          // }
        />
      </>
    </div>
  );
};
TemperatureInput.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  id: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.number,
  isReadonly: PropTypes.bool,
};
TemperatureInput.defaultProps = {
  label: "label",
  //   variant: "outlined",
  multiline: false,
  type: "number",
};
export default TemperatureInput;
