/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-06-29
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

 import React from "react";
 import { LinearProgress } from "@material-ui/core";
 import { NetworkCall } from "./networkcall";
 import { LocalStorageKeys, NetWorkCallMethods, refreshCacheAndReload, semverGreaterThan, localStorageKeys } from "./utils";
 import { actions } from "form-binder";
 import { connect } from "react-redux";
 class AppAuth extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
       loading: false
     };
   }
 
   componentDidMount() {
     let urlParams = new URLSearchParams(window.location.search);
     let metadataIdFromURL = urlParams?.get("metadata_id");
     let tokenFromUrl = urlParams.get("token");
     let repo = urlParams.get("repo");
 
     if (metadataIdFromURL && tokenFromUrl) {
       localStorage.setItem(localStorageKeys.metaDataId, metadataIdFromURL);
       localStorage.setItem(localStorageKeys.auth_token, tokenFromUrl);
     }
 
     if(repo){
       let data = JSON.parse(atob(repo))
       let arr =[]
       data.map(val => {
         arr.push(val.route)
       })
       localStorage.setItem("repos", JSON.stringify(arr))
     }
 
     let parsedToken;
     let userInfo = {};
     if (tokenFromUrl) {
       parsedToken = tokenFromUrl?.split(".")[1];
       userInfo = JSON.parse(window.atob(parsedToken));
       localStorage.setItem(localStorageKeys.userInfo, JSON.stringify(userInfo))
      //  localStorage.setItem("DBNAME", "ATP_Platform_DEV")
     }
 
     this.updateApplicationVersion();
     // this.refreshAPI();
     this.logOutCheckInCrossBrowser();
   }
 
   logOutCheckInCrossBrowser = () => {
     window.addEventListener('storage', e => {
 
       if (e.key === LocalStorageKeys.authToken && e.oldValue && !e.newValue) {
         alert("You have been logged out, Click ok to Reload");
         window.location.reload();
       }
     });
   }
 
 
   refreshAPI = async () => {
     if (localStorage.getItem(LocalStorageKeys.authToken)) {
 
       await Promise.resolve(this.props.REFRESH()).then(res => {
 
         this.setState({ loading: false }, () => {
           if (res?.payload?.error) {
             localStorage.setItem(LocalStorageKeys.authToken, '');
           } else {
             let data = res?.payload?.data;
             localStorage.setItem(LocalStorageKeys.authToken, data?.token_type + " " + data?.access_token);
           }
         })
       }).catch(err => {
 
         console.log('App Auth:', err)
         this.setState({
           loading: false
         })
       })
     } else {
       this.setState({
         loading: false
       })
     }
   }
 
   updateApplicationVersion = () => {
     NetworkCall(
       `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
       NetWorkCallMethods.get,
       null,
       null,
       false,
       true).then((_) => {
         const isVersion = semverGreaterThan(_.data.version, localStorage.getItem(LocalStorageKeys.version));
         localStorage.setItem(LocalStorageKeys.version, _.data.version)
         if (isVersion) {
           refreshCacheAndReload();
         }
       }).catch(err => {
         console.log('err:', err);
       })
   }
 
   render() {
 
     let {
       loading
     } = this.state;
 
     return (
       <>
         {loading ? (
           <LinearProgress />
         ) : this.props.children}
       </>
     );
   }
 }
 const mapStateToProps = (state) => ({
   auth: state?.authSlice?.login,
 });
 
 export default connect(mapStateToProps, actions)(AppAuth);
 