import React from 'react'
import {
    makeStyles,
    Divider,
    Typography,
    Grid,
    Button
} from '@material-ui/core';
import { actions } from 'form-binder'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationList } from '../notificationList';
import { AlertContext } from '../../contexts';
import { AlertProps } from '../../utils';

const { NOTIFICATION_ALLREAD_STATUS, NOTIFICATIONS_COUNTS, GET_ALL_NOTIFICATIONS } = actions

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    header: {
        padding: '10px 20px'
    }
}))

export const NotificationDrawer = (props) => {

    const classes = useStyles();
    // Declaring Store dispatch
    const dispatch = useDispatch()

    const { loading } = useSelector(
        state => state?.notificationSlice?.notificationAllReadStatus
    )

    const alert = React.useContext(AlertContext);

    const callFunction = () => {
        dispatch(GET_ALL_NOTIFICATIONS());
        dispatch(NOTIFICATIONS_COUNTS());
    };

    const onSaveBtnClicked = () => {

        let __params = {

        }

        Promise.resolve(dispatch(NOTIFICATION_ALLREAD_STATUS(__params))).then(res => {
            let resStatus = res.payload.error;

            if (!resStatus) {
                callFunction();
            } else {

                let statusMessage = "Notification AllRead Status Updated Failed !";

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
                    msg: resStatus ? statusMessage : '',
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })

            }

        });

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                direction='column'
                justify='flex-start'
                alignItems='stretch'
            >
                {
                    <Grid
                        item
                        container
                        justify="space-between"
                        display="flex"
                        alignItems="center"
                    >
                        <Typography className={classes.header}>
                            {"Notifications"}
                        </Typography>
                        <Button
                            disabled={loading}
                            onClick={onSaveBtnClicked}
                            color='secondary'
                        >
                            {loading ? "Marking..." : "Mark All As Read"}
                        </Button>
                    </Grid>
                }
                {<Divider />}

                <Grid item>
                    <NotificationList />
                </Grid>
            </Grid>
        </div>
    )
}
