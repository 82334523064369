import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  // Button,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { ComponentType, EmbedType, FormComponents } from "../../utils";
import ComponentUIPreview from "./componentUIPreview";
// store
import QuestionProperties from "./questionProperties";
import { convertToRaw, Entity } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import {
  // DependencyTable,
  CustomTable,
  CustomTableFixedRow,
  UploadReports,
  EmbedInput,
  FormluaBuilder,
  Expand,
  OrdinaryTable,
  CustomTableType
} from "../../components";
import { DrawerComponent } from "../../componentsV2";

// for drag and drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import copy from "fast-copy";
import { DialogContext } from '../../contexts';
import { v4 as uuidv4 } from "uuid";

const componentsNote = {
  range_picker: "Provide Range (Eg. 0, 10)",
};

const useStyles = makeStyles((theme) => ({
  formFields: {
    paddingBottom: "20px",
    border: "1px solid rgb(224, 224, 224)",
    background: "rgb(245, 245, 245)",
  },
  inputFiled: {
    marginBottom: "16px",
  },
  selectComponentList: {
    marginTop: 0,
    color: "red",
  },
  addFloatingButton: {
    position: "absolute",
    right: "26px",
    bottom: "30px",
  },
  embtyMesg: {
    textAlign: "center",
    padding: "20px 10px",
  },
  optionNote: {
    textAlign: "end",
    fontSize: "12px",
    paddingRight: "6px",
  },
  previewIcon: {
    marginLeft: 4,
    marginTop: 4,
    cursor: "pointer",
  },
  root: {
    padding: 12,
  },
  paperRoot: {
    padding: "8px 16px 16px 16px",
    border: "1px solid #E0E0E0",
  },
  header: {
    display: "flex",
  },
  slNo: {
    marginRight: 8,
  },
  componentList: {
    width: 300,
  },
  headerRoot: {
    marginBottom: 16,
  },
  addDependency: {
    marginTop: 12,
  },
  formulaBilder:{
    '& .MuiDialogContent-root':{
      padding:'0px',
      paddingTop:'12px',
    },
    '& .MuiDialogContentText-root':{
      marginBottom:0
    },
    '& .MuiDialogActions-root':{
      padding:0
    },
    '& .MuiDialog-paper':{
      minWidth:750
    }
  }
}));

export default function FormLayer(props) {

  const classes = useStyles();

  const [formComponentList, setFormComponentList] = React.useState(null);

  // Declaring Store dispatch
  const dispatch = useDispatch();
  const allComponents = useSelector((state) => state?.formSlice?.getFormComponents ?? { loading: false, data: [], error: false });

  React.useLayoutEffect(() => {
    // const initialDataFetch = async () => {
        
    //     const data = allComponents?.data?.filter(val => skipElement.indexOf(val.value) === -1);
    //     setFormComponentList(data)
    // }
    const initialDataFetch = async () => {
      let skipElement = [ "Image_upload", "Image_only_upload",];
      const data = allComponents?.data?.filter((val => val.value !== "popup_switch") && (val => skipElement.indexOf(val.value) === -1))
      setFormComponentList(data)
    }
    initialDataFetch();

  }, [dispatch, props.id, allComponents])

  const dialogContext = React.useContext(DialogContext);



  // ComponentUIPreview
  const initalState = {
    anchorEl: null,
    data: null
  }

  const [state, setState] = React.useState(initalState);

  const handlePopoverOpen = (event, data) => {
    // debugger
    setState({ ...state, anchorEl: event.currentTarget, data: data });
  };

  const handlePopoverClose = () => {
    setState(initalState);
  };

  const open = Boolean(state.anchorEl);

  const [drawerOpen, setDrawerOpen] = useState({
    open: false,
    index: null
  });

  const toggleDrawer = async (val, index) => {
    try {
      if (val) {
        await props.getSectionComponents(true);
      }
      setDrawerOpen({
        open: val,
        index: index ?? null
      })
    }
    catch (err) {
      console.log('err:', err)

    }
  }

  const giveMeQuestionProps = (data) => {
    const newData = copy(data);
    return newData;
  };

  const onExtractMentions = (raw) => {
    let mentionedFields = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedFields.push(ent.data.mention);
      }
    }
    return mentionedFields;
  };

  const handleEmbedComponentChange = (newValue, index) => {
    // debugger;
    // Take the contentState
    const contentState = newValue.getCurrentContent();
    let newContentState = contentState;
    const raw = convertToRaw(contentState);

    const allBlocks = contentState.getBlockMap();
    // debugger;
    allBlocks.map((block) => {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null && Entity.get(entityKey).getType() === "mention"
          );
        },
        (start, end) => {
          // Got a single entity here from a block
          // Now update the entity using the key
          let currentEntityKey = block.getEntityAt(start);

          let currentEntity = contentState.getEntity(currentEntityKey);

          // Add uuid for every single entity (with type "mention")
          if (!("uniqueId" in currentEntity.data.mention)) {
            newContentState = contentState.mergeEntityData(currentEntityKey, {
              mention: { ...currentEntity.data.mention, uniqueId: uuidv4() },
            });
          }
        }
      );
    });

    const newStateRaw = convertToRaw(newContentState);

    props?.onChangeComponents(
      JSON.stringify(newStateRaw),
      index,
      "editorState"
    );
    props?.onChangeComponents(
      onExtractMentions(newStateRaw),
      index,
      "embedFields"
    );
    // const { entityRanges } = raw?.blocks?.[0]

    // console.log(contentState.getBlockMap())
    // debugger
  };

  const handleIconClick = (index,val) => {
    dialogContext.setDialog({
        ...dialogContext,
        open: true,
        fullWidth: true,
        maxWidth: "md",
        title:"",
        className:classes.formulaBilder,
        body: <FormluaBuilder 
                formula={val?.formula ?? ''}
                save={(formula)=>applyFormula(formula,index)}
                formData={props?.fromData}
                cancel={cancel}
              />,
        positiveBtn: "Ok",
        negativeBtn: "CANCEL",
        negativeBtnDontShow: true,
        positiveBtnDontShow: true,
    })
};

const applyFormula = (formula,index) =>{
    console.log(formula)
    props.onChangeComponents(formula, index, "formula")
    dialogContext.setDialog({open:false})
}

const cancel = (formula,index) =>{
    dialogContext.setDialog({open:false})
}

  return (
    <>
      <DragDropContext
        onDragEnd={(params) => {
          const srcI = params?.source?.index;
          const descI = params?.destination?.index;
          props?.fromData?.splice(
            descI,
            0,
            props?.fromData?.splice(srcI, 1)[0]
          );
        }}
      >
        {/* {props?.fromData?.length > 0 ? props?.fromData?.map((val, index) => {
            if (val.component === "popup_switch") {
                return false
            } */}

        <Droppable droppableId="droppable-1">
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props?.fromData?.length > 0 ? (
                props?.fromData?.map((val, index) => {
                  if (val.component === "popup_switch") {
                    return false;
                  }
                  return (
                    // <Grid key={index} className={classes.root}>
                    //     <Paper elevation={0} className={classes.paperRoot}>
                    //         <Grid
                    //             container
                    //             direction="row"
                    //             justify="space-between"
                    //             alignItems="center"
                    //             className={classes.headerRoot}
                    //         >

                    //             <Grid item className={classes.header}>

                    //                 <Typography
                    //                     className={classes.slNo}
                    //                     component={"span"}
                    //                     color={"textPrimary"}>
                    //                     {index + 1 - props.indexOfno}{"."}
                    //                 </Typography>

                    <Draggable
                      key={val.priority}
                      draggableId={"draggable-" + val.priority}
                      index={index}
                    >
                      {(provided, _) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={val.priority}
                        >
                          <Grid
                            key={index}
                            style={{ padding: "20px 20px 0px 20px" }}
                          >
                            <Paper
                              style={{
                                padding: "8px 20px 3px 20px",
                                border: "1px solid #E0E0E0",
                              }}
                              elevation={0}
                            >
                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid item style={{ display: "flex" }}>
                                  <Grid style={{ minWidth: "26px" }}>
                                    <Typography
                                      component={"span"}
                                      color={"textPrimary"}
                                    >
                                      {index + 1 - props.indexOfno}
                                      {"."}
                                    </Typography>
                                  </Grid>

                                  <Autocomplete
                                    className={classes.componentList}
                                    onChange={(e, newValue) =>
                                      {
                                        // debugger
                                        props.onChangeComponents(
                                          newValue?.value,
                                          index,
                                          "component"
                                        )
                                      }
                                    }
                                    value={
                                      formComponentList?.filter(
                                        (getvalue) =>
                                          getvalue.value === val?.component
                                      )?.[0] ?? null
                                    }
                                    options={formComponentList}
                                    getOptionLabel={(option) => option.label}
                                    id="clear-on-escape"
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField
                                        error={
                                          val?.error?.componentError ?? false
                                        }
                                        {...params}
                                        className={classes.selectComponentList}
                                        size="small"
                                        placeholder={"Select Question type"}
                                        fullWidth
                                        margin={"dense"}
                                      />
                                    )}
                                  />

                                  <VisibilityRoundedIcon
                                    className={classes.previewIcon}
                                    color={"action"}
                                    fontSize={"small"}
                                    aria-owns={
                                      open ? "mouse-over-popover" : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={(e) =>
                                      handlePopoverOpen(e, val)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={val?.required ?? false}
                                        onChange={(e) =>
                                          props.onChangeComponents(
                                            !val?.required ?? false,
                                            index,
                                            "required"
                                          )
                                        }
                                        name="checkedA"
                                      />
                                    }
                                    label="Required"
                                  />
                                  <Tooltip
                                    title="Delete Question"
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        props.delectQuestion(index)
                                      }
                                    >
                                      <DeleteOutlineOutlinedIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                  <Grid
                                    {...provided.dragHandleProps}
                                    style={{
                                      display: "flex",
                                      padding: 12,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Drag Here" placement="top">
                                      <FlipToFrontIcon />
                                    </Tooltip>
                                  </Grid>
                                  {/* <Grid> */}
                                  <IconButton
                                    onClick={() => toggleDrawer(true, index)}
                                  >
                                    {/* <IconButton onClick={()=>openDrawer(index)}> */}
                                    <SettingsOutlinedIcon />
                                  </IconButton>
                                  {/* </Grid> */}
                                </Grid>

                                {/* <Grid {...provided.dragHandleProps} >
                                                            <Tooltip title="Drag Here" placement="top">

                                                                <FlipToFrontIcon />
                                                            </Tooltip>
                                                        </Grid> */}
                              </Grid>

                              <Grid item container direction="row" spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  className={classes.inputFiled}
                                >
                                  <TextField
                                    value={val?.label ?? ""}
                                    onChange={(e) =>
                                      props.onChangeComponents(
                                        e.target.value,
                                        index,
                                        "label"
                                      )
                                    }
                                    fullWidth
                                    label="Question / Title"
                                    id="question"
                                    variant="outlined"
                                    size="small"
                                    error={val?.error?.labelError ?? false}
                                    helperText={
                                      val?.error?.labelError
                                        ? "Please give the question (must be more then two character)"
                                        : ""
                                    }
                                    InputProps={{
                                      endAdornment:val?.component === "formula_builder" &&
                                      <InputAdornment position="end">
                                          <IconButton
                                              size="small"
                                            onClick={()=>handleIconClick(index,val)}
                                          >
                                            <Expand width={14} height={14} color={'rgba(0, 0, 0, 0.54)'} />
                                          </IconButton>
                                        </InputAdornment>
                                    }}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  className={classes.inputFiled}
                                >
                                  <TextField
                                    value={val?.tag ?? ""}
                                    onChange={(e) =>
                                      props.onChangeComponents(
                                        e.target.value,
                                        index,
                                        "tag"
                                      )
                                    }
                                    fullWidth
                                    label="#Tag"
                                    id="tag"
                                    variant="outlined"
                                    size="small"
                                    error={val?.error?.tagError ?? false}
                                    helperText={
                                      val?.error?.tagError
                                        ? "Please give the tag (must be more then two character)"
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                              {drawerOpen.open && index === drawerOpen.index && (
                                <DrawerComponent
                                  open={
                                    drawerOpen.open &&
                                    index === drawerOpen.index
                                  }
                                  isLarge
                                  onClose={() => toggleDrawer(false)}
                                >
                                  <div>
                                    <QuestionProperties
                                      dependency={val?.dependency}
                                      data={giveMeQuestionProps(val)}
                                      options={val?.options}
                                      parentSection={props.parentSection}
                                      selectedSection={props.selectedSection}
                                      fromData={props?.fromData ?? []}
                                      addDeleteComponents={
                                        props?.addDeleteComponents
                                      }
                                      updateDependency={props?.updateDependency}
                                      index={index}
                                      onChangeComponents={
                                        props?.onChangeComponents
                                      }
                                    // editorState={val?.editorState}
                                    // val={val}
                                    />
                                  </div>
                                </DrawerComponent>
                              )}
                              {ComponentType(val?.component ?? "") && (
                                <Grid item className={classes.inputFiled}>
                                  <Typography
                                    color={"textSecondary"}
                                    className={classes.optionNote}
                                  >
                                    {componentsNote[val?.component] ??
                                      "UI - Separated by a enter / use multiple tags"}
                                  </Typography>

                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    size={"small"}
                                    options={[]}
                                    value={val?.options}
                                    freeSolo
                                    onChange={(e, newValue) =>
                                      props?.onChangeComponents(
                                        newValue,
                                        index,
                                        "options"
                                      )
                                    }
                                    renderTags={(value, getTagProps) =>
                                      value?.map((option, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Options"
                                        placeholder="Options"
                                        error={val?.error?.optionError ?? false}
                                        helperText={
                                          val?.error?.optionError
                                            ? "Please add minimum two options required"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                              )}

                              {val?.component === FormComponents.embed_input && (
                                <Grid item className={classes.inputFiled}>
                                  <EmbedInput
                                    onChange={(value) =>
                                      handleEmbedComponentChange(value, index)
                                    }
                                    // onChange={(newValue, label) => {
                                    //   props?.onChangeComponents(
                                    //     JSON.stringify(newValue),
                                    //     index,
                                    //     "editorState"
                                    //   );

                                    //   // Generating unique id for fields
                                    //   // props?.onChangeComponents(
                                    //   //   getMentionFields(newValue),
                                    //   //   index,
                                    //   //   "mentions"
                                    //   // );
                                    // }}
                                    value={val}
                                  // value={{
                                  //   editorState: '{"blocks":[{"key":"a98sm","text":"Textfield is what i need","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":9,"key":0}],"data":{}}],"entityMap":{"0":{"type":"mention","mutability":"SEGMENTED","data":{"mention":{"name":"Textfield","type":"input_text"}}}}}'
                                  // }}
                                  ></EmbedInput>
                                </Grid>
                              )}

                              {(val?.component === "Image_upload" ||
                                val?.component === "Image_only_upload") && (
                                  <Grid item className={classes.inputFiled}>
                                    <Typography
                                      color={"textSecondary"}
                                      className={classes.optionNote}
                                    >
                                      {"Upload Image"}
                                    </Typography>

                                    <UploadReports
                                      id={"uploadReport" + index}
                                      onChange={(newFValue) =>
                                        props?.onChangeComponents(
                                          newFValue,
                                          index,
                                          "file"
                                        )
                                      }
                                      value={val?.file}
                                      single={true}
                                    // label={data?.question}
                                    />
                                    <div style={{color:'red'}}> 
                                                           { //Check if message failed
                                                             (val?.error?.fileuploaderror
                                                             ? <div> Please Upload the image</div> 
                                                              : "" )
                                                             }
                                                    </div>
                                  </Grid>
                                )}

                              {val?.component === "multi_select_tag" && (
                                <Grid container>
                                  <Grid
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    style={{
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      type={"number"}
                                      label="Max Selection"
                                      variant="outlined"
                                      size="small"
                                      value={val?.validation?.max_option}
                                      onChange={(e) => {
                                        let validation = val.validation
                                          ? val.validation
                                          : {};
                                        props?.onChangeComponents(
                                          {
                                            ...validation,
                                            max_option: e.target.value,
                                          },
                                          index,
                                          "validation"
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {/* {
                                                        ComponentType(val?.component ?? "") && <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={val?.has_dependency_qn ?? false}
                                                                        onChange={(e) => props?.onChangeComponents(!val?.has_dependency_qn ?? false, index, "has_dependency_qn")}
                                                                        name="hasDependencyQn"
                                                                    />
                                                                }
                                                                label="Show another question / form / section based on answer"
                                                            />
                                                        </Grid>
                                                    } */}

                              {val?.component === "custom_table" && (
                                <CustomTable
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}
                              {val?.component === "custom_table_2" && (
                                <CustomTableFixedRow
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}
                              {val?.component === "ordinary_table" && (
                                <OrdinaryTable
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}
                              {val?.component === "table_type_3" && (
                                <CustomTableType
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}

                              {/* {
                                                        val?.has_dependency_qn && <Grid item>

                                                            <DependencyTable
                                                                data={val?.dependency}
                                                                options={val?.options}
                                                                parentSection={props?.parentSection}
                                                                selectedSection={props?.selectedSection}
                                                                quetions={props?.fromData}
                                                                removeDependency={props?.addDeleteComponents}
                                                                updateDependency={props?.updateDependency}
                                                                parentIndex={index}
                                                            />

                                                            <Button
                                                                className={classes.addDependency}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                color={"primary"}
                                                                onClick={() => props?.addDeleteComponents(val, index, true)}
                                                            >
                                                                + Add
                                                            </Button>
                                                        </Grid>
                                                    } */}
                            </Paper>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <Typography
                  className={classes.embtyMesg}
                  color={"textSecondary"}
                >
                  You are yet to add a Question to this form
                </Typography>
              )}
              <ComponentUIPreview
                open={open}
                data={state?.data}
                anchorEl={state?.anchorEl}
                handlePopoverClose={handlePopoverClose}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
