import React, { useLayoutEffect } from "react";
// store
import { actions } from "form-binder";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
  Grid,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch, useSelector } from "react-redux";

const { GET_SECTION_COMPONENTSv1 } = actions;

const options = {
  filterType: false,
  selectableRows: false,
  download: false,
  print: false,
  viewColumns: false,
  rowsPerPageOptions: false,
  rowsPerPage: 10,
  tableBodyMaxHeight: "320px",
  setRowProps: (row, dataIndex, rowIndex) => {
    return {
      style: { textAlign: "center" },
    };
  },
  setTableProps: () => {
    return {
      style: { overflowY: "scroll" },
    };
  },
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const initalData = {
  formError: false,
  loading: true,
  name: "",
  skip: false,
  questions: [],
};


export default function MuiDataTable(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [fromData, setFromData] = React.useState(initalData);
  const allComponents = useSelector(
    (state) =>
      state?.formconf?.get_components ?? {
        loading: false,
        data: [],
        error: false,
      }
  );



  useLayoutEffect(() => {
    let params = {
      section_id: props.section_id ?? null,
    };
    setFromData(initalData);

    Promise.resolve(dispatch(GET_SECTION_COMPONENTSv1(params))).then((res) => {
      const data = {
        loading: false,
        name: "",
        skip: false,
        questions: [],
      };

      if (
        res?.payload?.data &&
        res?.payload?.data?.[0]?.section_id
      ) {
        const changeOptionLabel = (data) => {
          let array = [];
          data.map((val) => array.push({ label: val }));
          return array;
        };

        const childUp = res?.payload?.data?.[0]?.questions.map(
          (val) => {
            let child = {
              id: val?.id ?? null,
              label: val?.question ?? "",
              is_smart: val?.isSmart ?? false,
              required: val?.isRequired ?? false,
              component: val?.uiComponent ?? allComponents?.data?.[0]?.value,
              priority: val?.priority ?? 1,
              options: changeOptionLabel(
                val?.question_options
              ),
            };
            data.questions.push(child);
            return val;
          }
        );
        setFromData(data);
        return childUp;
      }
    });
  }, [props.section_id, dispatch, allComponents]);

  const value = fromData.questions;
  const component = props.component;

  let newData = [];
  value.map((item, index) => {
    newData.push({ sl: index + 1, ...item });
    return newData;
  });

  setTimeout(() => {
    setLoading(true);
  }, 1500);


  return (
    <>
      {loading ? (
        <Paper className={classes.root}>
          <MUIDataTable
            title={props.title}
            columns={[
              {
                name: "sl",
                label: "S.No",
                options: {
                  filter: true,
                  sort: true,
                },
              },
              {
                name: "label",
                label: "Question Name",
                options: {
                  filter: true,
                  sort: false,
                },
              },
              {
                name: "options",
                label: "Option",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (dataIndex) => {
                    if (dataIndex.length > 0) {
                      var output = dataIndex
                        .map((val) => val.label)
                        .join()
                        .replaceAll(",", ", ");
                      return output;
                    } else return "-";
                  },
                },
              },
              {
                name: "component",
                label: "Question Type",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (dataIndex) => {
                    let val = "";
                    for (var i = 0; i < component.length; i++) {
                      if (dataIndex === component[i].value) {
                        val = component[i].label;
                      }
                    }
                    return val;
                  },
                },
              },
              {
                name: "required",
                label: "Required",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (dataIndex) => {
                    let val = dataIndex === true ? "Yes" : "No";
                    return val;
                  },
                },
              },
            ]}
            data={newData}
            options={options}
          />
        </Paper>
      ) : (
          <>
            <Grid style={{ padding: "20px" }}>
              <Skeleton variant="text" width={"100%"} height={70} />
              <Skeleton variant="text" width={"100%"} height={48} />
              <Skeleton variant="text" width={"100%"} height={30} />
              <Skeleton variant="text" width={"100%"} height={30} />
              <Skeleton variant="text" width={"100%"} height={30} />
              <Skeleton variant="text" width={"100%"} height={60} />
            </Grid>
          </>
        )}
    </>
  );
}
