/**
 * @author Barath Viknesh
 */
import React, { useState } from 'react'
import { Grid, Container, TextField, Typography, Button, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
//  import { LoginSuccess } from '../../router/access'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'form-binder'
import { Routes } from '../../router/routes'
//  import { LocalStorageKeys } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto'
  },
  signInFont: {
    fontSize: 30,
    marginBottom: 10
  },
  credentialText: {
    fontSize: 16,
    color: '#83838C',
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },
    //  marginBottom:40
  },
  forgotPasswordText: {
    color: theme.palette.ternary.dark,
    //  color:'#1492E6',
    fontSize: 14,
    marginTop: 5,
    marginBottom: 30,
    width: '100%',
    cursor: "pointer",
  },
  signUpDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    '& .MuiTypography-root': {
      fontSize: 14
    },
    '& .MuiTypography-body1': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
  },
  signInBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: '100%',
    marginTop: '15px',
    padding: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  logInBtn: {
    // backgroundColor: theme.palette.primary.main,
    // color: '#fff',
    width: '100%',
    marginTop: '15px',
    padding: 10,
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
    }
  },

  aingaLogo: {
    display: 'flex',
    marginBottom: 10,
    '& img': {
      width: 187,
      height: 'auto'
    }
  },
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      alignItems: 'center'
    },

  },
  textInputs: {
    width: '100%',
    '& .MuiTextField-root': {
      marginTop: 30
    }
  },
  container: {
    backgroundColor: '#fff',
    height: '100vh',
    overflow: 'auto'
  },
  globeWrapper: {
    //  height:'100vh',
    //  overflow:'hidden'
  },
  backgroundImg: {
    background: 'url(/images/globe.jpg)',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  ippcName: {
    marginBottom: "20px"
  }
}))

export const ForgotPassword = props => {

  const classes = useStyles(props);

  const [state, setState] = useState({
    email_id: "",
    error: {
      email_id: "",
    },
    message: '',
    errorStatus: ''
  })

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector(
    state => state?.authSlice?.forgotPassword
  )

  const validate = () => {

    let isValidated = true;

    if (!state.email_id) {
      isValidated = false;
      state.error.email_id = "Enter email id";
    }

    //  if (!state.password) {
    //    isValidated = false;
    //    state.error.password = "Enter password";
    //  }

    setState({ ...state })
    return isValidated;
  }

  const resetPassword = () => {

    if (!validate()) {
      return false;
    }

    let params = {
      emailId: state.email_id,
    }

    Promise.resolve(dispatch(actions.FORGOT_PASSWORD(params))).then((res) => {
      setState({ ...state, message: res?.payload?.message ?? "Invalid Credentials", errorStatus: res?.payload?.error })
    })
  }

  const LoginPage = () => {
    history.push(Routes.login);
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      resetPassword()
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container style={{
          height: '100%'
        }}>
          <Hidden smDown>
            <Grid item md={6} className={classes.globeWrapper}>
              <div className={classes.backgroundImg}>
                {/* <img src="/images/globe.jpg"alt="ippc" className={classes.imgFluid} /> */}
              </div>
            </Grid>
          </Hidden>
          <Grid item md={6} container direction="column" justify="center" alignItems="center">
            <Grid item sm={8} md={10} className={classes.wrapper}>
              <div className={classes.aingaLogo}>
                {/* <img src="images/icons/ippc_newlogo.png" alt="ippc" /> */}
              </div>
              {/* <Typography variant="h6" className={classes.ippcName} >International Pandemic Preparedness Center</Typography> */}
              <Typography variant="h1" className={classes.signInFont}>Forgot Password ?</Typography>
              <Typography variant="h6" className={classes.credentialText}>Please Enter your IPPC Registered E-mail ID.</Typography>
              <div className={classes.textInputs}>
                <TextField
                  fullWidth
                  label="Email Id"
                  variant="standard"
                  value={state.email_id}
                  placeholder={'ippc@example.com'}
                  error={state.error.email_id}
                  helperText={state.error.email_id}
                  onChange={(e) => setState({ ...state, email_id: e.target.value, error: { ...state.error, email_id: "" } })}
                  onKeyPress={(e) => handleEnter(e)}
                />
              </div>

              <div>{state.errorStatus ?
                <Typography variant="body1" gutterBottom align="center" color={"error"} style={{ marginTop: '10px' }}>
                  {state.message}
                </Typography> : <Typography variant="body1" gutterBottom align="center" color={"secondary"} style={{ marginTop: '10px' }}>
                  {state.message}
                </Typography>
              }
              </div>
              <Button
                variant='contained'
                color='secondary'
                // fullWidth
                disabled={loading}
                onClick={resetPassword}
                className={classes.signInBtn}
              >
                {loading ? "Reset Password..." : "Reset Password"}
              </Button>

              <Button
                variant='outlined'
                color="primary"
                // fullWidth
                disabled={loading}
                onClick={LoginPage}
                className={classes.logInBtn}
              >
                {loading ? "Login in..." : "Login In"}
              </Button>


            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
