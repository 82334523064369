import React from 'react';
import
{
    Grid,
    TextField,
    makeStyles,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { ToggleButtonComponent } from '../../../components';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root:{
        flex: 1
    },
    gridItem:{
        padding:theme.spacing(2),
    },
}))

export const DateValidations = (props) =>{

    const {validationState,onChange} = props;
    
    const classes = useStyles(props);

    const toggleButtonData = [
        {
            label:'Hide Past Dates',
            value:'Hide Past Dates',
        },
        {
            label:'Hide Future Dates',
            value:'Hide Future Dates',
        },
        {
            label:'Custom Range',
            value:'Custom Range',
        },
    ];

    const currentDateChecked = (val,key) =>{
        if(val){
            onChange(moment(new Date()).format("YYYY-MM-DD"),key)
        }
        else{
            onChange(val,key)
        }
    }


    return(
        <div className={classes.root}>
            <Grid container spacing={props.addGridSpacing ? 2 : 0}>
                <Grid item xs={12} className={classes.gridItem} id="#gridItem">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={validationState?.currentDate ?? false}
                            onChange={(e)=>currentDateChecked(e.target.checked,'currentDate')}
                            name="currentDate"
                            color="secondary"
                        />
                        }
                        label="Set Current Date as Default"
                    />
                </Grid>
                <Grid item xs={12} className={classes.gridItem} id="#gridItem">
                    <ToggleButtonComponent
                        toggleButtonData={toggleButtonData}
                        isSelected={validationState?.toggleSelectedData ?? ''}
                        exclusive={true}
                        onSelected={(selected) =>onChange(selected,'toggleSelectedData')}
                    />
                </Grid>
                    {
                        validationState.toggleSelectedData === "Custom Range" &&
                        <React.Fragment>
                            <Grid item xs={12} md={6} className={classes.gridItem} id="#gridItem">
                                <form noValidate>
                                    <TextField
                                        // id={`${props?.data?.id}-date-${rowIndex}-${columnIndex}`}
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        value={validationState?.fromDate ?? ''}
                                        onChange={(event) => onChange(event.target.value,'fromDate')}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.gridItem} id="#gridItem">
                                <form noValidate>
                                    <TextField
                                        // id={`${props?.data?.id}-date-${rowIndex}-${columnIndex}`}
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        value={validationState?.toDate ?? ''}
                                        onChange={(event) => onChange(event.target.value,'toDate')}
                                    />
                                </form>
                            </Grid>
                            </React.Fragment>
                    }
            </Grid>
        </div>
    )
}

export default DateValidations;