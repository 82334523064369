import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { DrawerContext, DialogContext, BackdropContext, AlertContext } from '../../contexts';
import { CardList, InternatlServerError, NotFoundSection, SearchFilter, CardListLoader } from '../../components';
import { Message, DrawerProps, ConvertToReactSelect, subNavBarArr, crud, amIableToDoThis, AlertProps, __baseUrl__ } from '../../utils';

import { AddEditForm } from './addEditForm';

// store
import { actions } from "form-binder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { queries } from './aqlQueries';
// Store Action's list
const { UPSERT_FORM } = actions;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    search: {
        paddingBottom: theme.spacing(2),
        position: "sticky",
        top: 0,
        paddingTop: theme.spacing(2),
        background: theme.palette.background.default,
        zIndex: theme.zIndex.appBar + 1
    },
    progressBar: {
        position: 'absolute',
        width: '100%'
    }
}));

export const ListForms = props => {

    const classes = useStyles(props);

    const history = useHistory();

    const alert = React.useContext(AlertContext);

    const [data, setData] = React.useState([]);

    // Declaring Store dispatch

    const dispatch = useDispatch();

    const authData = useSelector(
        state => state?.authSlice?.login
    )

    const formTypes = useSelector(
        (state) => state?.formSlice?.getAllFormTypes
    );

    const drawerContext = React.useContext(DrawerContext);
    const dialogContext = React.useContext(DialogContext);
    const backdrop = React.useContext(BackdropContext);

    let {
        // data = [],
        loading,
        error
    } = props;




    const getForms = async (searchKey = "") => {

        let formName = searchKey.trim();
        let sortField = 'form_name';
        let sortDirection = 'ASC';

        let _ = await fetch( `${__baseUrl__}/execute_aql`

        ,

            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    db_name: localStorage.DBNAME,
                    query: queries.getAllForms(formName, sortField, sortDirection),
                }),
            });
        
        _ = await _.json();
        
        setData(_);
       return _
    }

    React.useEffect(()=>{
        getForms();
    },[])


    const onViewFormClicked = (data) => {
        history.push(Routes.manage_form_detail_parent + data?.form_id);
    }

    const onAddFormClicked = () => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <AddEditForm onActionCompleted={onActionCompleted} />,
            onClose: () => onActionCompleted()
        })
    }

    const onActionCompleted = () => {
        onDrawerClosed();
        getForms(document.getElementById("ippc_search_bar").value, true);
    }

    const onDrawerClosed = () => {
        drawerContext.setDrawer({
            ...drawerContext,
            direction: DrawerProps.direction.right,
            open: false
        })
    }

    const onEditIconClicked = (data) => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <AddEditForm isEdit={true} editData={data} onActionCompleted={onActionCompleted} />,
            onClose: () => onDrawerClosed()
        })
    };

    const onDeleteIconClicked = (data) => {
        dialogContext.setDialog({
            ...dialogContext,
            open: true,
            title: Message.manage_form_delete_title,
            body: Message.manage_form_delete_message,
            positiveBtn: Message.manage_form_delete_btn,
            negativeBtn: "Cancel",
            onOk: () => deleteCountry(data),
        })
    };

    const deleteCountry = (data) => {

        dialogContext.setDialog({ ...dialogContext, open: false })
        backdrop.setBackDrop({ ...backdrop, open: true, message: Message.manage_form_delete })

        Promise.resolve(dispatch(UPSERT_FORM({
            formId: data.form_id,
            isActive: false,
        }))).then(res => {
            backdrop.setBackDrop({ ...backdrop, open: false });
            getForms();

            let resStatus = res.payload.error;
            alert.setSnack({
                ...alert,
                open: true,
                severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
                msg: resStatus ? "Form deletion Failed !" : "Form deleted Successfully !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        });

    };

    // const onNotificationCountClicked = (data) => {
    //     history.push(Routes.notification_detail_parent + data?.form_id);
    // };

    const onSearchChange = (value) => {
        if (value && value?.length % 3 === 0) {
            getForms(value);
        } else if (!value) {
            getForms();
        }
    };

    const sortOptionArray = [
        { "value": "all", "label": "All Form Types" },
        ...ConvertToReactSelect(formTypes?.data, "form_type_id", "form_type_name")
    ];

    let previlages = {
        create: true,
        read: true,
        update: true,
        delete: true
    }



    return (
        <div className={classes.root}>

            {/* Search Filter Button Component */}
            <div className={classes.search}>
                <SearchFilter
                    placeholder="Search form"
                    loading={data?.length > 0 ? loading : false}
                    onSearchChange={onSearchChange}
                    buttonName="Add Form"
                    onActionButton={onAddFormClicked}
                    hideFilter={true}
                    hideSearchBar={false}
                    showSort={false}
                    sort={props.sort}
                    sortOptions={sortOptionArray}
                    handleSortChange={props.handleSortChange}
                    hideButton={false}
                />
            </div>

            {loading && !error && data?.length === 0 && <CardListLoader count={10} />}
            {!loading && error && data?.length === 0 && <InternatlServerError message={Message.manage_form_error} onRetry={getForms()} />}
            {!loading && !error && data?.length === 0 && <NotFoundSection message={Message.manage_form_not_found} />}

            {data?.length > 0 && previlages.read && <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {data?.map((form, key) => (
                    <Grid item key={key}>
                        <CardList
                            type="5"
                            hideAvatar={true}
                            title={form?.form_name}
                            form_id={form?.form_id}
                            show_card={true}
                            card_title={form?.form_type?.form_type_name ?? "Form Type"}
                            // count={form?.notification_count ?? 0}
                            // count_name={"Notifications"}
                            // onCountClicked={() => onNotificationCountClicked(form)}
                            // iconButton={false}
                            hideCountButton={true}
                            actionBtnName={"View Form"}
                            actionBtnCopyName={"Copy Form ID"}
                            onActionBtnClicked={() => onViewFormClicked(form)}
                            // onActionBtnCopyClicked={() =>setCopy(form?.form_id)}
                            onEditIconClicked={() => onEditIconClicked(form)}
                            onDeleteIconClicked={() => onDeleteIconClicked(form)}
                            hideActionBtn={!previlages.update}
                            hideClickCopy={!previlages.update}
                            isMoreBtn={previlages.update || previlages.delete}
                            hideEditBtn={!previlages.update}
                            hideDeleteBtn={!previlages.delete}
                        />
                    </Grid>
                ))}
            </Grid>}
        </div>
    )
}
