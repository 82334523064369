/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-06-21
 * @desc Collection of all the routes used in the application.
 */

export const Routes = {
  login: "/",
  home: "/forms",

  //1. Auth routes
  

  world_clock: "/world_clock",

  //1.1 forgot password
  forgot_password: "/forgotPassword",

  //2. Masters routes
  //2.1 Country routes
  list_countries: "/countries",
  country_detail_parent: "/country/",
  country_detail: "/country/:id",
  country_location_parent: "/country/location/",
  country_location: "/country/location/:id",
  country_patient_detail_parent: "/country/patient_detail/",
  country_patient_detail: "/country/patient_detail/:id",

  //2.2 Disease routes
  list_diseases: "/diseases",

  //3. User related routes
  //3.1 Roles
  roles: "/roles",
  role_previlages_parent: "/role/previlage/",
  role_previlages: "/role/previlage/:id",
  role_data_security_parent: "/role/data_security/",
  role_data_security: "/role/data_security/:id",
  role_manage_notification_parent: "/role/manage_notification/",
  role_manage_notification: "/role/manage_notification/:id",

  //3.2Users
  users: "/users",

  //4. Manage Forms
  manage_forms: "/manage_forms",
  manage_form_detail_parent: "/manage_form/",
  manage_form_detail: "/manage_form/:id",

  //5. Notifiable Forms
  add_form_notify_parent: "/notify/add/",
  add_form_notify: "/notify/add/:id",
  edit_form_notify_parent: "/notify/edit/",
  edit_form_notify: "/notify/edit/:id/:entry_id",
  view_form_notify_parent: "/notify/view/",
  view_form_notify: "/notify/view/:id/:entry_id",

  // 6. verify Forms
  view_form_verify_parent: "/verify/view/",
  view_form_verify: "/verify/view/:id/:entry_id",

  // 7. register Forms
  view_form_register_parent: "/register/view/",
  view_form_register: "/register/view/:id/:entry_id",

  // 8. investigator Forms
  view_form_investigator_parent: "/investigator/view/",
  view_form_investigator: "/investigator/view/:id/:entry_id",
  edit_form_investigator: "/investigator/edit/:id/:entry_id",

  notifications: "/notifications",
  notification_detail_parent: "/notification/",
  notification_detail: "/notification/:id",

  verifications: "/verifications",
  verification_detail_parent: "/verification/",
  verification_detail: "/verification/:id",

  case_registration: "/case_registrations",
  case_registration_detail_parent: "/case_registration/",
  case_registration_detail: "/case_registration/:id",

  investigations: "/investigations",
  investigation_detail_parent: "/investigation/",
  investigation_detail: "/investigation/:id",


  manage_patients: "/manage_patients",
 

  power_lens: "/powerlens",
  power_lens_detail_parent: "/powerlens/",
  power_lens_detail: "/powerlens/:id",

  r3: "/r3",
  r3_detail_parent: "/r3/",
  r3_detail: "/r3/:id",

  home_page: "/home",

  dashboard_page: "/dashboard",

  notify: "/notify",

};

export default Routes;
