import React from 'react';
import { makeStyles, Grid, Typography, Divider, Button } from '@material-ui/core';
import { TabSelect } from '../';
import { useDispatch, useSelector } from 'react-redux';
import { AlertContext, BackdropContext } from '../../contexts'
import { AlertProps } from '../../utils';
import { actions } from 'form-binder';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        padding: theme.spacing(2)
    },
    header: {
        padding: theme.spacing(1)
    }
}))

export const AddColumn = ({
    data = [],
    source = '',
    onColumnAdditionCompleted = () => false
}) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const backDrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);

    const { data: authData } = useSelector(
        state => state?.authSlice?.login
    )

    let stateData = authData?.[source]?.map(_ => _) ?? [];

    const [state, setState] = React.useState([...stateData]);

    const [saving, setSaving] = React.useState(false);

    const onChange = (index, data, value) => {
        state[index] = value;
        setState(state)
    }

    const onSaveBtnClicked = () => {

        let params = {
            [source]: state,
            user_profile_id: authData.user_profile_id
        }

        setSaving(true);

        Promise.resolve(dispatch(actions.ADD_COLUMN_TO_LIST(params))).then(async (res) => {
            await Promise.resolve(dispatch(actions.GIVE_ME_USER_PROFILE()));
            onColumnAdditionCompleted();
            setSaving(false);
            backDrop.setBackDrop({
                ...backDrop,
                open: false
            })
            if (!res.payload.error) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Column Updated Successfully!!!`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })

            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: 'Something went wrong! Try Again',
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            }
        })
    }

    return <div className={classes.root}>

        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid item>
                <Typography className={classes.header}>
                    {`Add Column`}
                </Typography>
            </Grid>

            <Grid item>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={onSaveBtnClicked}
                    disabled={saving}
                >
                    {`${saving ? 'Saving...' : 'Save'}`}
                </Button>
            </Grid>
        </Grid>

        <Divider />

        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
            className={classes.container}
        >
            {data?.map((_d, key) => <Grid key={key} item>
                <TabSelect
                    label={`${_d?.name} ${_d.is_investigator_section ? '(Investagation)' : ''}`}
                    value={state?.[key]}
                    items={_d?.columns ?? []}
                    multiple={true}
                    onClick={(value) => onChange(key, _d, value)}
                    notFoundMsg={"No Tags found in the form"}
                />
            </Grid>)}
        </Grid>
    </div>
}