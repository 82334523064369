import React, { useRef, useState, useCallback } from "react";
import {
  ContentState,
  EditorState,
  Modifier,
  convertToRaw,
  convertFromRaw,
  // Entity,
  CompositeDecorator,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import editorStyles from "./MultiMentionTriggers.module.css";
import "@draft-js-plugins/mention/lib/plugin.css";
// import mentions from "./Mentions";
import { getEntityRanges } from "../../utils";
import {
  Button,
  Typography,
  ButtonGroup,
  makeStyles,
  // TextField,
  Grid,
  // Divider,
  // withStyles,
  InputAdornment,
  // Input,
  InputBase,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

// const mentionPlugin = createMentionPlugin({ mentionTrigger: ["@", "#"] });
// const { MentionSuggestions } = mentionPlugin;
// const plugins = [mentionPlugin];

const useStyle = makeStyles(() => ({
  button: {
    padding: 1,
    margin: 2,
    textTransform:"none",
  },
  variable_button: {
    background: "#D67402",
    color: "white",
    "&:hover": {
      background: "#D67646",
      color: "white",
    },
  },
  util_button: {
    background: "#DE3CAE",
    color: "white",
    "&:hover": {
      background: "#DE3CAE",
      color: "white",
    },
    padding: "0.05rem 1rem 0.05rem 1rem",
  },
  ButtonGroup: {
    padding: "0 0.25rem 0 0.25rem",
  },
  ButtonTitles: {
    padding: "0.5rem 0 0 0",
    color: "#110F47",
    fontSize: 14,
  },
  save_button:{
    padding: "6px 12px",
    marginLeft: 8,
    background:"#007AFF",
    color:"white",
    "&:hover": {
      background: "#007AFF",
      color: "white",
    },
  },
  cancel_button:{
    color:'#007AFF'
  },
  clear_btm:{
    borderColor: "#c16969",
    color:"#c16969",
    "&:hover": {
      borderColor: "#c16969",
      color: "#c16969",
    },
  },
  operatorButton:{
    minWidth: 20,
    padding: "5px 11px",
    marginTop:"1em"
  },
  flexEnd:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    paddingRight:12
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: `2px solid #110F4714`,
    fontSize: 16,
    width: "100%",
    padding: "2px 12px",
    // color:"#D6D6D614",
    // height: "20.4px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),

    "&:focus": {
      border: `4px solid #110F4714`
      // borderColor: theme.palette.primary.dark,
    }
  },
  input_file:{
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: `0px solid #110F4714`,
    fontSize: 16,
    width: "72%",
    maxWidth: "100%",
    padding: "2px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),

    "&:focus-within":{
      border: `4px solid #110F4714`,
    },

    "&:focus": {
      border: `4px solid #110F4714`
      // borderColor: theme.palette.primary.dark,
    }
  },
  formula_header:{
    borderBottom:'2px solid #110F4714',
    paddingBottom:12
  },
  formula_body:{
    '& .MuiGrid-grid-md-8':{
      borderRight:'2px solid #110F4714',
      padding:16
    },
    '& .MuiGrid-grid-md-4':{
      padding:16
    }
  }
}
));


let variables = [
  {
    name: "Height",
    label: "Height",
    tag: "Height",
    trigger: "@",
  },
  {
    name: "Weight",
    label: "Weight",
    tag: "Weight",
    trigger: "@",
  },
  {
    name: "Age ",
    label: "Age",
    tag: "Age",
    trigger: "@",
  },
  {
    name: "Gender",
    label: "Gender",
    tag: "Gender",
    trigger: "@",
  },
  
];

let util_functions = [
  // {
  //   label: "Sum",
  //   formula: "@Height + @Weight",
  //   trigger: "#",
  // },
  // {
  //   label: "Multiply",
  //   formula: "@Height * @Weight",
  //   trigger: "#",
  // },
  // {
  //   label: "BMI",
  //   formula: "@weight / ( @height * @height )",
  //   trigger: "#",
  // },
];


export const Mentions = (props) => {
  const ref = useRef(null);
  const classes = useStyle();

  const returnVariables = () =>{
    return props?.formData?.filter(l=>l.tag)?.map(_=>({
      name: _.tag,
          label: _.tag,
          tag: _.tag,
          trigger: "@",
      }))
  }

  const mentions = returnVariables()
  
  const New_Mentions = [
    {
      name: "Sum",
      label: "Sum",
      formula: "@age + @gender",
      trigger: "#",
    },
    {
      name: "Multiply",
      label: "Multiply",
      formula: "@gender * @age",
      trigger: "#",
    },
    {
      name: "BMI",
      label: "BMI",
      formula: "@weight / ( @height * @height )",
      trigger: "#",
    }
  ];

  const mention_obj = {
    "@": mentions,
    "#": New_Mentions
  }
  

  const [editorState, setEditorState] = useState(
    props?.formula ? EditorState.createWithContent(ContentState.createFromText(props?.formula))
                  : EditorState.createEmpty()
    );
  const [formulaName, setFormulaNameValue] = useState("Untitled Formula")

  const [searchValue, setSearchValue] = useState("");

  const [open, setOpen] = useState(false);
  // const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState(mention_obj["@"]);

  const getCUstomSuggestion = (searchValue, suggestions, trigger) => {
    const value = searchValue.toLowerCase();
    const triggerSuggestions =
      trigger && !Array.isArray(suggestions)
        ? suggestions[trigger]
        : suggestions;
    let filteredSuggestions = triggerSuggestions.filter(
      (suggestion) =>
        !value || suggestion.name.toLowerCase().indexOf(value) > -1
    );

    // filteredSuggestions = filteredSuggestions?.map((x) => ({
    //   ...x,
    //   name: x?.formula ? x?.formula : x?.name,
    // }));

    const length =
      filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
    return filteredSuggestions.slice(0, length);
  };

  const { MentionPlugin, MentionSuggestions, MentionDecorators, custom_composite_decorator } =
    React.useMemo(() => {
      const MentionPlugin = createMentionPlugin({ mentionTrigger: ["@", "#"] });
      const MentionSuggestions = MentionPlugin.MentionSuggestions;
      const MentionDecorators = MentionPlugin.decorators;
      const decorators = [MentionDecorators];
      let custom_composite_decorator = [];
      if (decorators?.length) {
        const decoratorsArr = decorators
          ?.map((decorator) => decorator)
          .flat(Infinity);
        custom_composite_decorator = new CompositeDecorator(decoratorsArr);
        // return EditorState.createWithContent(contentState, decorator);
      }
      return {
        MentionPlugin,
        MentionSuggestions,
        MentionDecorators,
        custom_composite_decorator,
      };
    }, []);

  const onChange = useCallback((_editorState) => {
    
    setEditorState(_editorState);
    mention_obj['#'].map((x) => {
      if(x.name !== x.label)
        x.name = x.label
      return x
    })
  }, []);

  const clearEditor = () => {
    // setEditorState(EditorState.createEmpty(custom_composite_decorator))
    setEditorState(EditorState.push(editorState, ContentState.createFromText(""), custom_composite_decorator))
  }
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ trigger, value }) => {
    // setSuggestions(defaultSuggestionsFilter(value, mention_obj, trigger));
    setSuggestions(getCUstomSuggestion(value, mention_obj, trigger));
  }, []);

  const createMentionEntities = (text, tags) => {
    
  //  debugger
    let current_entity_range = convertToRaw(editorState.getCurrentContent()).blocks?.[0].entityRanges
    const rawContent = convertToRaw(ContentState.createFromText(`${editorState.getCurrentContent().getPlainText()} ${text}`));
    
    const rawState = tags?.map((tag) => ({
      type: "mention",
      mutability: "SEGMENTED",
      data: {
        mention: {
          name: tag?.label,
          label: tag?.label,
          tag: tag?.tag,
          trigger: tag?.trigger,
        }
      },
    }));

    rawContent.entityMap = [...rawState];

    rawContent.blocks = rawContent.blocks.map((block) => {
      const ranges = [ ...current_entity_range, ...block.entityRanges];
      tags.forEach((tag, index) => {
        
        const entityRanges = getEntityRanges(block.text, tag.label, index);
        console.log(entityRanges)
        if (entityRanges) {
          ranges.push(...entityRanges);
          // block.entityRanges.push(...entityRanges)
        }
      });
      return { ...block, entityRanges: ranges };
    });

   
    return convertFromRaw(rawContent);
  };

  const getButtonAction = (text, createFieldMentions = false) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    let nextContentState = Modifier.insertText(
      contentState,
      selection,
      `${createFieldMentions ? `@${text}` : text} `
    );
    console.log(convertToRaw(editorState.getCurrentContent()))

    setEditorState(
      EditorState.push(editorState, nextContentState, "insert-fragment")
    );
  };

  const getFieldButtonAction = (payload) => {
    // debugger
    // let temp_tags = [...tags];
    // temp_tags.push(payload);
    // setTags(temp_tags);
    

    
    let nextContentState = createMentionEntities(payload.label, [...mention_obj['@'], ...mention_obj['#']], );
    setEditorState(
      EditorState.moveFocusToEnd(EditorState.push( editorState, nextContentState, "insert-fragment"))
    );
   
  };

  const saveEditorValues = () => {
    // console.log(formulaName)
    // console.log(editorState.getCurrentContent().getPlainText())
    props?.save && props?.save(editorState.getCurrentContent().getPlainText())
  }
  const cancelEditorValues = () => {
    props?.cancel && props?.cancel()
  }



  const plugins = [MentionPlugin];

  return (
    <>
      <Grid container maxWidth>
        <Grid item>

        <Grid container className={classes.formula_header}>
                <Grid item xs={8} sm={8} md={9}>
                  <InputBase
                    id="standard-adornment-weight"
                    value={formulaName}
                    className={classes.input_file}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => setFormulaNameValue(e.target.value)}
                    placeholder={`Formula Name`}
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={3} className={classes.flexEnd}>
                  <Button className={classes.cancel_button} onClick={() => cancelEditorValues()}>Cancel</Button>
                  <Button className={classes.save_button} onClick={() => saveEditorValues()}>Apply</Button>
                </Grid>
              </Grid>


          <Grid container className={classes.formula_body} >
            <Grid item sm={6} md={8}>
              
            
              
              <div
                className={editorStyles.editor}
                onClick={() => {
                  ref.current.focus();
                }}
              >
                <Editor
                  editorState={editorState}
                  onChange={onChange}
                  plugins={plugins}
                  ref={ref}
                  decorators={MentionDecorators}
                />
                <MentionSuggestions
                  open={open}
                  onOpenChange={onOpenChange}
                  onSearchChange={onSearchChange}
                  suggestions={suggestions}
                  onAddMention={(mention) => {
                    if(mention?.trigger === '#')
                    {
                      mention.name = mention?.formula
                    }
                  }}
                />
              </div>
              <div>
                <div style={{display:"flex", justifyContent:"end"}}>
                <ButtonGroup
                  className={classes.ButtonGroup}
                  variant="outlined"
                  aria-label="outlined button group"
                  
                >
                  <Button fullWidth={true}  className={`${classes.operatorButton} ${classes.clear_btm}`} onClick={() => clearEditor()}> Clear </Button>
                </ButtonGroup>
                </div>
                <ButtonGroup
                  className={classes.ButtonGroup}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" + ")}>+</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" - ")}>-</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" * ")}>*</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" / ")}>/</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" % ")}>%</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" ^ ")}>^</Button>
                </ButtonGroup>

                <ButtonGroup
                  className={classes.ButtonGroup}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" = ")}>=</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" < ")}>{`<`}</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" > ")}>{`>`}</Button>
                  <Button
                    className={classes.operatorButton}
                    onClick={() => getButtonAction(" <= ")}
                  >{`<=`}</Button>
                  <Button
                    className={classes.operatorButton}
                    onClick={() => getButtonAction(" >= ")}
                  >{`>=`}</Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(" != ")}>!=</Button>
                </ButtonGroup>

                <ButtonGroup
                  className={classes.ButtonGroup}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(` && `)}> AND </Button>
                  <Button className={classes.operatorButton} onClick={() => getButtonAction(` || `)}> OR </Button>
                </ButtonGroup>

                {/* <ButtonGroup
                  className={classes.ButtonGroup}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button className={`${classes.operatorButton} ${classes.clear_btm}`} onClick={() => clearEditor()}> Clear </Button>
                </ButtonGroup> */}
              </div>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
            >
              <InputBase
                id="standard-adornment-weight"
                value={searchValue}
                className={classes.input}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={`Search`}
                type="outlined"
                endAdornment={
                  <InputAdornment>
                    <SearchOutlined />{" "}
                  </InputAdornment>
                }
              />

              <div style={{ marginTop: "1rem"}}>
               
                  <Typography className={classes.ButtonTitles} variant="body1">
                    Form Fields
                  </Typography>
                  <div style={{ overflow: "auto", maxHeight:"7rem" }}>
                    <div>
                      {returnVariables()?.length && returnVariables()?.map((x, index) => {
                    return (
                      <Button
                        className={`${classes.button} ${classes.variable_button}`}
                        key={index}
                        onClick={() =>
                          getFieldButtonAction({ label: x.label, tag: x.tag })
                          // getButtonAction(`@${x.name}`)
                        }
                      >
                        {x.label}
                      </Button>
                    );
                      })}
                    </div>
                  </div>
                {/* <Divider /> */}
                
                  <Typography className={classes.ButtonTitles} variant="body1">
                    Util Function
                  </Typography>
                  <div style={{ overflow: "auto", maxHeight: "7rem" }}>
                    <div>
                      {util_functions?.length ? (util_functions.map((x, index) => {
                    return (
                      <Button
                        onClick={() => getButtonAction(x.formula)}
                        className={`${classes.button} ${classes.util_button}`}
                        key={index}
                      >
                        {x.label}
                      </Button>
                    );
                      }) ) : (
                        <Typography variant="caption">There are no util functions added as of now</Typography>
                      )}
                    </div>
                  </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
